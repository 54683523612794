import { useLocale } from "locales";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const ResetPasswordScreen = React.lazy(
  () => import("services/ResetPasswordScreen")
);

// import NotFound from "../pages/NotFound";

// import ScheduleDetailService from "services/ScheduleDetails";
const WorkerPannelPage = React.lazy(
  () => import("components/cells/CompanyWorkersView/WorkerPannel")
);
const OrgWorkerTaskService = React.lazy(() => import("services/WorkerTask"));
const WorkerTaskService = React.lazy(
  () => import("services/WorkerTask/WorkerSideService")
);

const ScheduleDetailService = React.lazy(
  () => import("services/ScheduleDetails")
);
const NotFound = React.lazy(() => import("pages/NotFound"));

// import CompanyScheduleService from "services/CompanySchedule";

const HomeService = React.lazy(() => import("services/Home"));
const NewHomeService = React.lazy(() => import("pages/authPages/NewHome/Home"));

// SEO RELATED PATHS STARTS
const VacaturesKinderopvangUtrecht = React.lazy(
  () => import("services/SEO/vacatures-kinderopvang-utrecht")
);
const FreelancerKinderopvangUtrecht = React.lazy(
  () => import("services/SEO/freelancer-kinderopvang-utrecht")
);
const ZzpKinderopvangUtrecht = React.lazy(
  () => import("services/SEO/zzp-kinderopvang-utrecht")
);
const KinderopvangVacaturesDenHaag = React.lazy(
  () => import("services/SEO/kinderopvang-vacatures-den-haag")
);
const FreelancerKinderopvangDenHaag = React.lazy(
  () => import("services/SEO/freelancer-kinderopvang-den-haag")
);
const UitzendbureauKinderopvangDenHaag = React.lazy(
  () => import("services/SEO/uitzendbureau-kinderopvang-den-haag")
);
const ZzpKinderopvangDenHaag = React.lazy(
  () => import("services/SEO/zzp-kinderopvang-den-haag")
);
const WerkenMetKinderenDenHaag = React.lazy(
  () => import("services/SEO/werken-met-kinderen-den-haag")
);
const VacaturesKinderopvangAmsterdam = React.lazy(
  () => import("services/SEO/vacatures-kinderopvang-amsterdam")
);
const FreelancerKinderopvangAmsterdam = React.lazy(
  () => import("services/SEO/freelancer-kinderopvang-amsterdam")
);
const UitzendbureauKinderopvangAmsterdam = React.lazy(
  () => import("services/SEO/uitzendbureau-kinderopvang-amsterdam")
);
const ZzpKinderopvangAmsterdam = React.lazy(
  () => import("services/SEO/zzp-kinderopvang-amsterdam")
);
const VacaturesKinderopvangRotterdam = React.lazy(
  () => import("services/SEO/vacatures-kinderopvang-rotterdam")
);

const FreelancerKinderopvangRotterdam = React.lazy(
  () => import("services/SEO/freelancer-kinderopvang-rotterdam")
);

const UitzendbureauKinderopvangRotterdam = React.lazy(
  () => import("services/SEO/uitzendbureau-kinderopvang-rotterdam")
);


const WerkenMetKinderenRotterdam = React.lazy(
  () => import("services/SEO/werken-met-kinderen-rotterdam")
);

const ZzpKinderopvangRotterdam = React.lazy(
  () => import("services/SEO/zzp-kinderopvang-rotterdam")
);

//SEO RELATED PATHS ENDS

const LoadingScreen = React.lazy(
  () => import("pages/authPages/LoadingScreen/LoadingScreen")
);
const AboutUsService = React.lazy(() => import("services/AboutUs"));
const ContactUsService = React.lazy(() => import("services/ContactUs"));
const EmployeeService = React.lazy(() => import("services/Employee"));
const FindJobService = React.lazy(() => import("services/FindJobs"));
const PostJobService = React.lazy(() => import("services/PostJob"));

const PostAJob = React.lazy(() => import("services/PostAJob"));

const InviteWorkerPage = React.lazy(
  () => import("services/InviteWorker/InviteWorker")
);

const JobApplicantsService = React.lazy(
  () => import("services/JobApplicants/JobApplicants")
);
const EditAJob = React.lazy(() => import("services/EditAJob"));
const OrganizationJobsPage = React.lazy(
  () => import("pages/Workers/OrganizationJobsPage/OrganizationJobsPage")
);

const FinanceJobs = React.lazy(() => import("pages/Workers/FinanceJobs"));

const CompanyScheduleService = React.lazy(
  () => import("services/CompanySchedule")
);

const ScheduleChart = React.lazy(
  () => import("components/cells/CompanyWorkersView/Gantt.Chart")
);

const HiredWorkersService = React.lazy(() => import("services/HiredWorkers"));
const WorkerDetailsService = React.lazy(() => import("services/WorkerDetails"));
const FindWorkersService = React.lazy(() => import("services/FindWorkers"));
const PostJobDetailsService = React.lazy(
  () => import("services/PostJobDetails")
);
const JobDetailsService = React.lazy(
  () => import("services/JobDetailsService")
);
const JobOfferDetailsService = React.lazy(
  () => import("services/JobOfferDetailsService")
);
const JobOfferedServiceOrg = React.lazy(
  () => import("services/JobOfferDetailsServiceOrg")
);
const FrelanceService = React.lazy(() => import("services/Freelancer"));
const LoginService = React.lazy(() => import("services/Login"));
const NotificationService = React.lazy(() => import("services/Notification"));
const OrganizationService = React.lazy(() => import("services/Organization"));

const GuideLIne = React.lazy(() => import("services/GuideLine"));
const EmailVerification = React.lazy(
  () => import("services/EmailVerification")
);
const EmailVerificationSuccess = React.lazy(
  () => import("services/EmailVerificationSuccess")
);

const ForgotSuccess = React.lazy(() => import("services/forgotSuccess"));

const ForgotService = React.lazy(() => import("services/ForgotPassword"));
const AccountTypeSelection = React.lazy(() => import("services/AccountType"));
const RegisterAccuntDetails = React.lazy(() => import("services/Registration"));

const AccountService = React.lazy(() => import("services/Account"));

const PersonalScheduleService = React.lazy(
  () => import("services/PersonalSchedule")
);
const InboxService = React.lazy(() => import("services/Inbox"));
const Conversations = React.lazy(
  () => import("pages/Workers/InboxPage/Conversations")
);
const EmptyInbox = React.lazy(() => import("components/organisms/EmptyInbox"));
const Messages = React.lazy(() => import("pages/Workers/InboxPage/Messages"));

const MyJobService = React.lazy(() => import("services/MyJobs"));
const FinanceService = React.lazy(() => import("services/Finance"));
const WorkingShift = React.lazy(
  () => import("pages/Workers/FinancePage/workingShift")
);
const JobRates = React.lazy(() => import("pages/Workers/FinancePage/JobRates"));
const Invoices = React.lazy(() => import("pages/Workers/FinancePage/Invoices"));
const SettingService = React.lazy(() => import("services/Setting"));
const FAQService = React.lazy(() => import("services/FAQ"));
const StatsService = React.lazy(() => import("services/Stats"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const ProjectRoutes = () => {
  const { localeBasics } = useLocale();
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <Router>
        <ScrollToTop />
        <Routes>
          {/*  */}
          <Route path="/" element={<HomeService />} />

          <Route path="/newHome" element={<NewHomeService />} />

          {/* /faq */}
          <Route path="/FAQ" element={<FAQService />} />
          {/* /settings */}
          <Route path="/instellingen" element={<SettingService />} />
          {/* /notifications */}
          <Route path="/notificaties" element={<NotificationService />} />
          {/* /account */}
          <Route path="/account" element={<AccountService />} />

          {/*/personal-schedule  */}
          <Route path="/mijn-rooster" element={<PersonalScheduleService />} />

          {/* SEO RELATED PATHS STARTS */}
          <Route
            path="/vacatures-kinderopvang-utrecht"
            element={<VacaturesKinderopvangUtrecht />}
          />
          <Route
            path="/freelancer-kinderopvang-utrecht"
            element={<FreelancerKinderopvangUtrecht />}
          />
          <Route
            path="/zzp-kinderopvang-utrecht"
            element={<ZzpKinderopvangUtrecht />}
          />
          <Route
            path="/kinderopvang-vacatures-den-haag"
            element={<KinderopvangVacaturesDenHaag />}
          />
          <Route
            path="/freelancer-kinderopvang-den-haag"
            element={<FreelancerKinderopvangDenHaag />}
          />

          <Route
            path="/uitzendbureau-kinderopvang-den-haag"
            element={<UitzendbureauKinderopvangDenHaag />}
          />

          <Route
            path="/zzp-kinderopvang-den-haag"
            element={<ZzpKinderopvangDenHaag />}
          />

          <Route
            path="/werken-met-kinderen-den-haag"
            element={<WerkenMetKinderenDenHaag />}
          />
          <Route
            path="/vacatures-kinderopvang-amsterdam"
            element={<VacaturesKinderopvangAmsterdam />}
          />
          <Route
            path="/freelancer-kinderopvang-amsterdam"
            element={<FreelancerKinderopvangAmsterdam />}
          />
          <Route
            path="/uitzendbureau-kinderopvang-amsterdam"
            element={<UitzendbureauKinderopvangAmsterdam />}
          />
          <Route
            path="/zzp-kinderopvang-amsterdam"
            element={<ZzpKinderopvangAmsterdam />}
          />
          <Route
            path="/vacatures-kinderopvang-rotterdam"
            element={<VacaturesKinderopvangRotterdam />}
          />
          <Route
            path="/freelancer-kinderopvang-rotterdam"
            element={<FreelancerKinderopvangRotterdam />}
          />
          <Route
            path="/uitzendbureau-kinderopvang-rotterdam"
            element={<UitzendbureauKinderopvangRotterdam />}
          />

          <Route
            path="/werken-met-kinderen-rotterdam"
            element={<WerkenMetKinderenRotterdam />}
          />

          <Route
            path="/zzp-kinderopvang-rotterdam"
            element={<ZzpKinderopvangRotterdam />}
          />
          {/* SEO RELATED PATHS ENDS */}

          <Route
            path="/mijn-rooster/details"
            element={<ScheduleDetailService />}
          />
          {/* /finance */}
          <Route path="/financien" element={<FinanceService />}>
            {/* /shift */}
            <Route
              path=":hiredWorker/verschuiving"
              element={<WorkingShift />}
            />
            {/* jobRates */}
            <Route path=":hiredWorker/jobTarieven" element={<JobRates />} />
            {/* /invoices  -> /facturen*/}
            <Route path=":hiredWorker/facturen" element={<Invoices />} />
            <Route path="" element={<FinanceJobs />} />
          </Route>
          {/* /my-jobs */}
          <Route path="/mijn-banen" element={<MyJobService />} />
          {/* /my-jobs/invited */}
          <Route
            path="/mijn-banen/uitgenodigd/:invitationId"
            element={<JobOfferDetailsService />}
          />

          {/* <Route path="/messages/:userId" element={<InboxService />} /> */}

          <Route path="/inbox" element={<InboxService />}>
            <Route path="" element={<EmptyInbox />} />
            {/* /inbox/conversations */}
            <Route path="gesprekken" element={<Conversations />} />
            {/* /inbox/messages */}
            <Route path="berichten/:conversationsId" element={<Messages />} />
          </Route>

          {/* /vind-diensten */}
          <Route path="/vind-diensten" element={<FindJobService />} />
          {/* /job-details */}
          <Route
            path="/vacature-details/:jobId"
            element={<JobDetailsService />}
          />
          {/* /my-stats */}
          <Route path="/statistieken" element={<StatsService />} />

          {/* Organization Paths */}
          {/* <Route
            path="/job-details"
            element={<JobDetailsOrganizationService />}
          /> */}
          {/* /schedule */}
          <Route path="/rooster" element={<CompanyScheduleService />}>
            <Route
              path=""
              element={
                <EmptyInbox
                  title={localeBasics.TEXT_SELECT_WORKER}
                  text={localeBasics.TEXT_SELECT_WORKER_MESSAGE}
                />
              }
            />
            <Route path=":hiredWorkerId" element={<ScheduleChart />} />
            {/* /schedule/worker/list */}
            <Route path="werknemer/lijst" element={<WorkerPannelPage />} />
          </Route>
          {/* /hired-workers */}
          <Route
            path="/ingehuurde-medewerkers"
            element={<HiredWorkersService />}
          />
          {/* /job/create  */}
          <Route path="/dienst-aanmaken" element={<PostJobService />}>
            <Route path="" element={<PostAJob />} />
          </Route>
          {/* /jobs */}
          <Route path="/diensten" element={<PostJobService />}>
            {/* <Route path="post" element={<PostAJob />} /> */}
            {/* /jobs/update */}
            <Route path="update" element={<EditAJob />} />
            {/* /jobs/invited-job */}
            <Route
              path="uitgenodigde-baan/:invitationId"
              element={<JobOfferedServiceOrg />}
            />
            {/* /jobs/invite-worker */}
            <Route path="invite-worker/:jobId" element={<InviteWorkerPage />} />
            {/* /jobs/job-details */}
            <Route
              path="vacature-details/:jobId"
              element={<PostJobDetailsService />}
            />
            {/* /jobs/applicants */}
            <Route
              path="aanvragers/:jobId"
              element={<JobApplicantsService />}
            />
            <Route path="" element={<OrganizationJobsPage />} />
          </Route>
          {/* /find-workers */}
          <Route path="/vind-werknemers" element={<FindWorkersService />} />
          {/* /find-workers/:userdId */}
          <Route
            path="/werknemer-details/:userId"
            element={<WorkerDetailsService />}
          />

          {/* /hired-workers/task */}
          <Route
            path="/ingehuurde-medewerkers/taak/:hiredWorkerId"
            element={<OrgWorkerTaskService />}
          />
          {/* /job/task */}
          <Route
            path="/baan/taak/:hiredWorkerId"
            element={<WorkerTaskService />}
          />
          {/* Organization */}
          {/* /employee */}
          <Route path="/medewerker" element={<EmployeeService />} />
          {/* /freelancer */}
          <Route path="/freelancer" element={<FrelanceService />} />
          {/* /organization */}
          <Route path="/organisatie" element={<OrganizationService />} />
          {/* /about */}
          <Route path="/over-ons" element={<AboutUsService />} />
          {/* /contact */}
          <Route path="/contact" element={<ContactUsService />} />

          {/* /guide */}
          <Route path="/gids" element={<GuideLIne />} />
          {/* /register */}
          <Route path="/registreer" element={<EmailVerification />} />
          {/* /forgot */}
          <Route path="/vergeten" element={<ForgotService />} />
          {/* /verify-sccess */}
          <Route
            path="/verificatie-toegang"
            element={<EmailVerificationSuccess />}
          />
          {/* /fogot-sccess */}
          <Route path="/vergeten-succes" element={<ForgotSuccess />} />
          {/* /verified-email? */}
          <Route
            path="/geverifieerd-e-mailadres"
            element={<AccountTypeSelection />}
          />
          {/* /account-details */}
          <Route path="/accountgegevens" element={<RegisterAccuntDetails />} />
          {/* /reset-password  */}
          <Route path="/reset-wachtwoord" element={<ResetPasswordScreen />} />

          {/* /login */}
          <Route path="/login" element={<LoginService />} />
          {/* /algemenevoorwaarden  */}
          <Route
            path="/algemenevoorwaarden"
            element={
              <iframe
                src="https://backend.berenvriendjes.nl/Terms"
                width="100%"
                height="100%"
                title="terms"
                style={{
                  height: "100vh",
                }}
              />
            }
          />
          {/* /Privacy&Policy */}
          <Route
            path="/privacybeleid"
            element={
              <iframe
                src="https://backend.berenvriendjes.nl/Privacy"
                width="100%"
                height="100&"
                title="Privacy"
                style={{
                  height: "100vh",
                }}
              />
            }
          />

          <Route path="*" element={<NotFound />} />
          {/* /Cookies */}
          <Route
            path="/cookiesbeleid"
            element={
              <iframe
                src="https://backend.berenvriendjes.nl/Cookies"
                width="100%"
                height="100&"
                title="Privacy"
                style={{
                  height: "100vh",
                }}
              />
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;

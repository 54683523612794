import { AuthContext, ITokens } from "./@types";
import { createCtx } from "../utils";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { logoutApi, refreshTokenApi } from "utils/globalAPI/Auth";
import { ToastConsumer } from "store/ToastProvider";
import { OtpDecodeBase64Id } from "utils/hooks/helper";
import { useLocale } from "locales";
import { changeLocaleApi } from "utils/globalAPI/Account";
let RefresTokenData = {
  data: {
    data: {
      userData: null,
      accessToken: "",
      accountSetting: null,
      organization: null,
    },
  },
};

const [useUserAuth, SetUserAuthProvider] = createCtx<AuthContext>();

const useUserAuthCtx = (): AuthContext => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "refreshToken",
    "deviceId",
  ]);
  const [havingToken, setHavingToken] = useState(true);

  const getUserTokenFromCookies = () => {
    const refreshToken = cookies.refreshToken;
    const deviceId = cookies.deviceId;

    if (refreshToken && deviceId) {
      return {
        refreshToken,
        deviceId,
      };
    } else {
      setHavingToken(false);
      return {
        refreshToken: "",
        deviceId: "",
      };
    }
  };
  const { changeLocale, currentLocale } = useLocale();
  const [{ refreshToken, deviceId }, setRefreshToken] = useState<ITokens>({
    refreshToken: "",
    deviceId: "",
  });

  useEffect(() => {
    setRefreshToken(getUserTokenFromCookies());
  }, []);

  const [enabledRefreshToken, setEnabaledRefreshToken] = useState(false);
  const [signOutLoading, setSignOutLoading] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [fetchAPI, setFetchAPI] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(null);

  useEffect(() => {
    if (refreshToken) {
      setEnabaledRefreshToken(true);
    }
  }, [refreshToken]);

  const toast = ToastConsumer();

  const setTokenInCookies = ({ refreshToken, deviceId: id }: ITokens) => {
    setCookie("refreshToken", refreshToken, {
      path: "/",
      expires: dayjs().add(Number(30), "day").toDate(),
    });

    setRefreshToken({ deviceId: id, refreshToken });

    setCookie("deviceId", id, {
      path: "/",
      expires: dayjs().add(Number(30), "day").toDate(),
    });
  };

  let {
    data: {
      data: {
        data: { userData, accessToken, accountSetting },
      },
    } = RefresTokenData,
    isLoading: refreshLoading,
    isRefetching,
    refetch: refetchRefresh,
  } = useQuery(
    [
      "refreshToken",
      {
        enabledRefreshToken,
        deviceId,
      },
    ],
    () =>
      refreshTokenApi(
        { deviceId: OtpDecodeBase64Id(deviceId as string) },
        refreshToken
      ),

    {
      enabled:
        enabledRefreshToken && refreshToken && refreshToken?.length > 0
          ? true
          : false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
      onSuccess: (data) => {
        // console.log("apiResponss", data);
        setTokenInCookies({
          deviceId: deviceId,
          refreshToken: data?.data?.data?.refreshToken,
        });
        setFetchAPI(true);
        setHavingToken(false);
        if (selectedLocale === null) {
          changeLocale(data?.data?.data?.accountSetting?.locale);
        } else {
          changeLocale(selectedLocale);
          changeLocaleApi(
            {
              locale: selectedLocale,
            },
            data?.data?.data?.accessToken
          );
        }
      },
      onError: (error: any) => {
        setFetchAPI(false);
        if (error?.response?.status == 401) {
          toast.ServerError(error, "top-right");
          userSignout();
        }
        setHavingToken(false);
      },
    }
  );

  const userSignout = async () => {
    setSignOutLoading(true);
    try {
      await logoutApi(OtpDecodeBase64Id(deviceId as string), accessToken);

      wipeTokens();
      setSignOutLoading(false);
      setLogOutModal(false);
    } catch (err: any) {
      console.error("Error during logout:", err);
      toast.ServerError(err, "top-right");
      setSignOutLoading(false);
    }
  };

  const wipeTokens = () => {
    removeCookie("refreshToken", {
      path: "/",
      expires: new Date(dayjs().add(Number(30), "day").toString()),
    });

    removeCookie("deviceId", {
      path: "/",
      expires: new Date(dayjs().add(Number(30), "day").toString()),
    });
    refetchRefresh();
    setRefreshToken({ deviceId: "", refreshToken: "" });
    setEnabaledRefreshToken(false);
  };

  useEffect(() => {
    console.log("userData", userData, "accessToken", accessToken);
  }, [userData]);

  return {
    userSignout,
    setTokenInCookies,
    userData,
    accessToken,
    refreshLoading: refreshLoading || isRefetching || havingToken,
    refetchingRefreshToken: isRefetching,
    signOutLoading,
    logOutModal,
    setLogOutModal,
    fetchAPI,
    accountSetting,
    setSelectedLocale,
    refetchRefresh,
  };
};

const UserAuthProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useUserAuthCtx();
  return <SetUserAuthProvider value={auth}>{children}</SetUserAuthProvider>;
};

export const UserAuthContext = () => {
  const userAuth = useUserAuth();
  return userAuth;
};

export default UserAuthProvider;

import { TITLES } from "./TITLES";
import { PLACEHOLDERS } from "./PLACEHOLDERS";
import { LABELS } from "./LABELS";
import { BUTTONS } from "./BUTTONS";
import { DROPDOWN } from "./DROPDOWN";
import { ERRORS } from "./ERRORS";
import { LINKS } from "./LINKS";
import { SUCCESS } from "./SUCCESS";
import { createCtx } from "store/utils";
import { useState } from "react";

import { Locale, useLocelContextTypes } from "./@types";
import { BASIC_TEXTS } from "./BASIC_TEXT";
import { SERVER_RESPONSE } from "./SERVER_RESPONSE";
import CookieConsent from "react-cookie-consent";
import styles from "./index.module.scss";

export const getLang = (): Locale => {
  const lang = localStorage.getItem("language");
  // return "en";

  if (lang === "en") {
    return "en";
  } else {
    return "du";
  }
};
let lang: Locale = getLang();

let languagesList = ["en", "du"];

const localeBasics = BASIC_TEXTS[lang];
const localeTitles = TITLES[lang];
const localePlaceholders = PLACEHOLDERS[lang];
const localeLabels = LABELS[lang];
const localeButtons = BUTTONS[lang];
const localeDropdowns = DROPDOWN[lang];
const localeErrors = ERRORS[lang];
const localeLinks = LINKS[lang];
const localeSuccess = SUCCESS[lang];

export {
  localeBasics,
  localeTitles,
  localePlaceholders,
  localeLabels,
  localeButtons,
  localeDropdowns,
  localeErrors,
  localeLinks,
  localeSuccess,
};

export const [usLocale, Provider] = createCtx<useLocelContextTypes>();

const useLocaleCtx = () => {
  // const location = useLocation();

  let lang: Locale = getLang();
  const [texts, setStexts] = useState({
    localeBasics: BASIC_TEXTS[lang],
    localeTitles: TITLES[lang],
    localePlaceholders: PLACEHOLDERS[lang],
    localeLabels: LABELS[lang],

    localeButtons: BUTTONS[lang],
    localeDropdowns: DROPDOWN[lang],
    localeErrors: ERRORS[lang],
    localeLinks: LINKS[lang],
    localeSuccess: SUCCESS[lang],

    localeServerResponse: SERVER_RESPONSE[lang],
  });

  const [currentLocale, setCurrentLocale] = useState(lang);

  const changeLocale = (lang: "en" | "du") => {
    if (!languagesList.includes(lang)) return;
    setCurrentLocale(lang);

    localStorage.setItem("language", lang);
    lang = getLang();
    setStexts({
      localeBasics: BASIC_TEXTS[lang],
      localeTitles: TITLES[lang],
      localePlaceholders: PLACEHOLDERS[lang],
      localeLabels: LABELS[lang],

      localeButtons: BUTTONS[lang],
      localeDropdowns: DROPDOWN[lang],
      localeErrors: ERRORS[lang],
      localeLinks: LINKS[lang],
      localeSuccess: SUCCESS[lang],
      localeServerResponse: SERVER_RESPONSE[lang],
    });
  };

  return {
    ...texts,
    currentLocale,
    changeLocale,
  };
};

const LocaleProvider = ({ children }: { children: JSX.Element | any }) => {
  const value = useLocaleCtx();
  return (
    <Provider value={value}>
      <>
        {children}
        {window.location.pathname.toLocaleLowerCase() !== "/cookiesbeleid" && (
          <CookieConsent
            location="bottom"
            buttonText={value.localeButtons.BUTTON_ACCEPT + ""}
            cookieName="cookiesAccepts"
            style={{ background: "#F6F8FE" }}
            buttonStyle={{
              background: "#c9d5ff",
              color: "#101828",
              fontSize: "13px",
            }}
            expires={150}
          >
            <h2 className={styles["heading"]}>
              {value.localeTitles.TITLE_COOKIES_MODAL + ""}
            </h2>
            <p className={styles["text"]}>
              {value.localeBasics.TEXT_COOKIES_MODAL + ""}
              <a className={styles["link"]} href="/cookiesbeleid">
                {localeLabels.COOKIES}
              </a>
            </p>
          </CookieConsent>
        )}
      </>
    </Provider>
  );
};

export const useLocale = () => {
  const locale = usLocale();
  return locale;
};

export default LocaleProvider;

import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible: any) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const [isLangComponentVisible, setIsLangComponentVisible] =
    useState(initialIsVisible);
  const [isNotificationVisible, setIsNotificationVisible] =
    useState(initialIsVisible);
  const [isUserDropdownVisible, setIsUserDropdownVisible] =
    useState(initialIsVisible);
  const [isMessageSettingVisible, setIsMessageSettingVisible] =
    useState(initialIsVisible);
  const [isMessageEditDeleteVisible, setIsMessageEditDeleteVisible] =
    useState(initialIsVisible);
  const [isHeaderPagesVisible, setIsHeaderPagesVisible] =
    useState(initialIsVisible);
  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      setIsUserDropdownVisible(false);
      setIsLangComponentVisible(false);
      setIsNotificationVisible(false);
      setIsMessageSettingVisible(false);
      setIsMessageEditDeleteVisible(false);
      setIsHeaderPagesVisible(false);
    }
  };

  // const ref = useRef<any>(null);

  // const handleClickOutside = (event: any) => {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setIsSearchBarActive(false);
  //   }
  // };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    isLangComponentVisible,
    setIsLangComponentVisible,
    isNotificationVisible,
    setIsNotificationVisible,
    isUserDropdownVisible,
    setIsUserDropdownVisible,
    isMessageSettingVisible,
    setIsMessageSettingVisible,
    isMessageEditDeleteVisible,
    setIsMessageEditDeleteVisible,
    isHeaderPagesVisible,
    setIsHeaderPagesVisible,
  };
}

import { DefaultEventsMap } from "@socket.io/component-emitter";
import useComponentVisible from "Common/hooks/useComposite";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSocketIO } from "store/SocketConsumer";

import { UserAuthContext } from "store/UserAuthContext";
import { INotificaiton } from "types/schema";
import { Socket } from "socket.io-client";
import { ToastConsumer } from "store/ToastProvider";

export const useNotifications = (
  notification: INotificaiton[],
  sockets?: Socket<DefaultEventsMap, DefaultEventsMap>,
  connected?: boolean
) => {

  
  const { ref, setIsNotificationVisible, isNotificationVisible } =
    useComponentVisible(false);

  const [unreadCount, setUnreadCount] = useState(0);


  const toggle = () => {
    if (isNotificationVisible == true) {
      setIsNotificationVisible(false);
    } else {
      setIsNotificationVisible(true);
    }
  };

  const navigation = (link: string,navigate:NavigateFunction) => {
    navigate(link);
    setIsNotificationVisible(false);
  };
const [notifications,setNotification] = useState<INotificaiton[]>([])

  const toast = ToastConsumer()
  useEffect(() => {
    if (connected) {
      const getNotification = () => {
        sockets?.emit(
          "get-latest-three-notifications",
          (data: INotificaiton[], unread: number) => {
            setUnreadCount(unread);
            if (data?.length > 0) setNotification(data)
            else notification = [];
          }
        );
      };

      sockets?.on("notification", (data: {
        notification:INotificaiton
      }) => {
        // alert(data.notification.content)
        toast.success(data.notification.content,"top-right");

        getNotification();
      });
      getNotification();
    }

    return () => {
      sockets?.off("refresh-notification");
      sockets?.off("get-latest-three-notifications");
      sockets?.off("notification");
    };
  }, [sockets, connected]);

  const markAllAsRead = async () => {
    sockets?.emit("mark-all-as-read", (data: number) => {
      setNotification(notifications?.map(item=>({...item,isRead:true})))
      setUnreadCount(data);
    });
  };
  const updateNotificationReadAbility = async (
    link: string,
    notificationId: string,
    navigate:NavigateFunction
  ) => {
    sockets?.emit("read-notification", notificationId);
    navigate(link);
  };

  return {
    ref,
    navigation,
    toggle,
    isNotificationVisible,

    unreadCount,
    notifications,
    updateNotificationReadAbility,
    markAllAsRead,
  };
};

import { UserAuthContext } from "store/UserAuthContext";
import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
let newSocket: any = null;
const useSocket = (serverURL: string) => {
  const { userData, accessToken } = UserAuthContext();
  const [socket, setSocket] =
    useState<Socket<DefaultEventsMap, DefaultEventsMap>>();
  const [connected, setConnected] = useState(false);
  
  useEffect(() => {
    if (userData && accessToken && !connected) {
      newSocket = io(serverURL,{
        auth: {
          token: accessToken,
        },
        // transports:["websocket"],
        reconnectionDelay: 1000,
        reconnection:true,
        forceNew: true,
        timeout: 20000,
        autoConnect: true,
      });
      newSocket.on("connect", () => {
        setSocket(newSocket);
        newSocket?.emit("join_room", { user: userData?._id});
        setConnected(true);
      })
      newSocket?.on("disconnect", () => {
        setConnected(false);
        setSocket(undefined);
      });
    }
    return () => {
     newSocket?.disconnect?.();
    };
  }, [serverURL, userData,accessToken]);

  useEffect(() => {
    // emit disconnect event  when user logout
    if (!(userData && accessToken) && socket) {
      socket?.disconnect();
      setConnected(false);
    }
  }, [socket,userData]);
 return { socket, connected };
};
export default useSocket;

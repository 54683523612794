import React, { ChangeEvent, useEffect } from "react";
import { useState } from "react";
import { socketContextTypes } from "./@types";
import { createCtx } from "../utils";
import useSocket from "./hook";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { encodeString } from "utils/hooks/helper";
import { ToastConsumer } from "store/ToastProvider";
import { useLocale } from "locales";
import { IsLocal } from "utils/axios";
import { useNotifications } from "components/cells/NotificationDropDown/hook";
import { INotificaiton } from "types/schema";
export const [useSocketIo, SetSocketProvider] = createCtx<socketContextTypes>();

const useSocketIoCtx = (navigate?: NavigateFunction): socketContextTypes => {
  // const { socket, connected } = useSocket("http://localhost:3001/");
  let isProduction = process.env.NODE_ENV === "production";
  const { socket, connected } = useSocket(
    (isProduction
      ? process.env.REACT_APP_SOCKETE_URL
      : IsLocal
      ?
      // "http://192.168.0.107:3001"
       process.env.REACT_APP_SOCKETE_URL_DEV
      : process.env.REACT_APP_SOCKETE_URL) as string
  );

  const toast = ToastConsumer();
  const { localeServerResponse } = useLocale();
  let Notification: INotificaiton[] = [];

  const StartConversation = (user: string, navigate: NavigateFunction) => {
    if (!connected) {
      toast.error(localeServerResponse?.ERROR_UNKOWN, "top-right");
      return;
    }
    socket?.emit(
      "checkConversations",
      user,
      (data: { conversationId: string; isConversationExist: boolean }) => {
        //  ;
        navigate(`/inbox/berichten/${encodeString(data.conversationId)}?isNew=${!data.isConversationExist}`);
      }
    );
  };

  const notificationAPi = useNotifications(Notification, socket, connected);

  return {
    sockets: socket,
    connected: connected,
    StartConversation,
    notificationAPi,
  };
};
const SocketProvider = ({ children }: { children: JSX.Element }) => {
  const socketValue = useSocketIoCtx();
  return <SetSocketProvider value={socketValue}>{children}</SetSocketProvider>;
};
export const useSocketIO = () => {
  const socket = useSocketIo();
  return socket;
};
export default SocketProvider;

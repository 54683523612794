// contact us
//  <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>  

// one plateform
//  <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a>

// uitzendbureau
//  <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a>

// zzz
//  <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a>

//vacatures
// <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a>

//https://www.berenvriendjes.nl/freelancer-kinderopvang-amsterdam
// <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a>


import { Link } from "react-router-dom";
import styles from "../index.module.scss";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  BASIC_BLOCKED:
    "Je bent geblokkeerd door deze gebruiker of je hebt deze gebruiker geblokkeerd.",

  BASIC_STEP_1: "Steps to use Berenvriendjes",
  BASIC_STEP_2: "Meld je aan als werknemer of ZZP-er en creëer een profiel.",
  BASIC_STEP_3:
    "Meld u aan als werknemer of ZZP'er en ontdek direct beschikbare diensten die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala aan mogelijkheden, zodat u de perfecte baan kunt vinden.",
  BASIC_STEP_4:
    "Bekijk alle diensten op jouw locatie en meld je aan voor de perfecte baan.",
  BASIC_STEP_5:
    "Bij ons platform heb je de mogelijkheid om alle diensten in jouw directe omgeving te bekijken. Of je nu op zoek bent naar een tijdelijke baan, een flexibele opdracht, Of het nu gaat om de kinderopvang, de zorgsector of andere branches, er is altijd een passende opdracht voor jou.",
  BASIC_STEP_6: "Yes een match! Gefeliciteerd jouw eerste dienst.",
  BASIC_STEP_7:
    "Zodra je een passende match hebt gevonden, ben je klaar om direct aan de slag te gaan. We hebben er alle vertrouwen in dat je je dienst succesvol zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het succes en aan de tevredenheid van onze opdrachtgever.",
  TEXT_COOKIES_MODAL: `
      Onze website maakt gebruik van cookies om uw ervaring te verbeteren. Door
      onze website te gebruiken, stemt u in met ons cookiebeleid. Voor meer
      informatie, lees ons`,
  BASIC_STEP_1_Q: "Meld je aan als werknemer of ZZP-er en creëer een profiel.",
  BASIC_STEP_1_A: (
    <>
      Meld u aan als werknemer of ZZP'er en ontdek direct beschikbare diensten
      die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala
      aan mogelijkheden, zodat u de perfecte dienst kunt vinden.
      <br />
      <br />
      Tip: download de app om overal waar je bent een dienst binnen te halen!
    </>
  ),
  BASIC_STEP_2_Q:
    "Bekijk alle diensten binnen jouw omgeving en meld je aan voor de perfecte dienst.",
  BASIC_STEP_2_A:
    "Bij ons platform heb je de mogelijkheid om alle diensten in jouw directe omgeving te bekijken. Of je nu op zoek bent naar een tijdelijke baan, een flexibele dienst of een langdurige opdracht, Of het nu gaat om de kinderopvang, de zorgsector of andere branches, er is altijd een passende opdracht voor jou.",
  BASIC_STEP_3_Q:
    "Yes een match! Gefeliciteerd jouw eerste dienst. Klaar om aan de slag te gaan?",
  BASIC_STEP_3_A: (
    <>
      Zodra je een passende match hebt gevonden, ben je klaar om direct aan de
      slag te gaan. We hebben er alle vertrouwen in dat je je dienst succesvol
      zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het
      succes en aan de tevredenheid van onze opdrachtgever.
      <br />
      <br />
      <br />
      <br />
      <br />
      <ol>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ol>
    </>
  ),

  BASIC_URGENT_WARNING:
    "dienst wordt per direct geaccepteerd door een medewerker zonder acceptatie van organisatie.",
  BASIC_SOON_WARNING:
    "dienst wordt onmiddellijk door een medewerker geaccepteerd zonder acceptatie van de organisatie.",
  TEXT_CHANGE_DECIDED_RATE:
    "Na wijziging van het tarief wordt het oude tarief verwijderd en wordt het nieuwe tarief toegepast op de toekomstige diensten.",
  TEXT_REPEAT_CONTRACT:
    "Selecteer de datum waarop u het contract wilt herhalen of u kunt er een herhalend contract van maken.",
  TEXT_HOME_HERO_DESCRIPTION: (
    <>
      Schrijf je gratis in als werknemer of ZZP-er en zoek direct een
      werkopdracht! <br /> Schrijf je gratis in als Organisatie en vindt direct
      de perfecte medewerker voor jouw dienst!
    </>
  ),
  TEXT_NEW_HOME_HERO_DESCRIPTION: (
    <>
      At Berenvriendjes we offer vacancies for childcare in Utrecht and
      surrounding areas for you. If you have a passion for working with children
      and want to make a positive impact on their growth and well-being, then we
      have interesting vacancies available for you. You can with us count on
      working environments where personal development and fun are central.
    </>
  ),
  TEXT_WITH_US_YOU_HAVE_THE_FREEDOME_MSG:
    "Bij ons heb je de vrijheid om zelf te bepalen waar en wanneer je werkt, zowel in dienstverband als freelancer. Wij bieden mogelijkheden voor beide. Je hebt de vrijheid om te kiezen welke werkmodus het beste bij jouw behoeften en voorkeuren past.",
  TEXT_REGISTER_AS_AN_EMPLOYEE_OR_FREELANCER:
    "Schrijf u in als werknemer of ZZP'er en ontdek direct beschikbare diensten die aansluiten bij uw expertise en voorkeuren. Wij bieden een breed scala aan mogelijkheden, zodat u de perfecte werkopdracht kunt vinden.",
  TEXT_WITH_OUR_PLATFORM_YOU_HAVE_THE_OPPORTUNITY:
    "Bij ons platform heb je de mogelijkheid om alle diensten in jouw directe omgeving te bekijken. Of je nu op zoek bent naar een tijdelijke baan, een flexibele opdracht of een langdurige werkopdracht, Of het nu gaat om de kinderopvang, de zorgsector of andere branches, er is altijd een passende werkopdracht voor jou.",
  TEXT_ONCE_YOU_HAVE_FOUND_A_SUITABLE_MATCH_MSG:
    "Zodra je een passende match hebt gevonden, ben je klaar om direct aan de slag te gaan. We hebben er alle vertrouwen in dat je je dienst succesvol zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het succes en aan de tevredenheid van onze opdrachtgever. We hebben er alle vertrouwen in dat je je werkopdracht succesvol zult volbrengen. Jouw inzet en vaardigheden zullen bijdragen aan het succes van het project en aan de tevredenheid van onze opdrachtgever.",
  TEXT_REGISTER_FOR_FREE_CREATE_PROFILE_MSG:
    "Schrijf je gratis in en creëer een profiel",
  TEXT_CREATE_A_REPEATING_URGENT_OR_NORMAL_MSG:
    "Maak een herhalende, spoed of normale dienst aan",
  TEXT_CHOOSE_YOUR_EMPLOYEE_OR_FREELANCER_MSG:
    "Berenvriendjes zoekt naar de juiste gekwalificeerde medewerker",
  TEXT_YES_A_MATCH_YOUR_WORK_ASSIGNEMNT_COMPLETED:
    "Yes een Match. Je dienst is vervuld",
  TEXT_RATING: "beoordeling",
  TEXT_AVERAGE_RATING: "gemiddelde score",
  TEXT_TOTAL_REVIEWS: "Total Reviews",
  TEXT_EMPLOYEE_FREEDOM_MSG:
    "Geef uw personeel meer mogelijkheden door autonomie te verlenen, de werktevredenheid te vergroten en de productiviteit te verhogen. Ontdek de voordelen van het bevorderen van ‘Employee Freedom’ in uw organisatie.",
  TEXT_FREELANCER_FREEDOM_MSG:
    "Omarm de onafhankelijkheid en flexibiliteit van freelancen. Neem de controle over uw werk, tijd en leven terwijl u uw passies en projecten nastreeft op uw voorwaarden.,",
  TEXT_EMPLOYEE_FLEXIBILITY_MSG:
    "In een snel veranderende wereld is flexibiliteit uw concurrentievoordeel. Omarm veranderingen, draai met gemak en grijp nieuwe kansen wanneer ze zich voordoen. Ontdek de kracht van aanpassingsvermogen.,",
  TEXT_FREELANCER_FLEXIBILITY_MSG:
    "Bij Berenvriendjes is het zo dat werknemers meer vrijheid hebben om hun eigen klussen te kiezen. Hierdoor heb jij  meer controle over je eigen werkrooster en de projecten waar jij aan zou willen werken.",
  TEXT_NO_LONG_TERM_COMMITMENT_MSG:
    "Het idee dat je 'nergens aan vastzit' bij Berenvriendjes betekent dat er geen langdurige verplichtingen zijn voor werknemers. In traditionele uitzendbureaus kunnen werknemers vaak worden ingezet voor langere contracten bij specifieke werkgevers. Bij Berenvriendjes bepaal waar jij de nadruk opzet voor kortere klussen of lange projecten, waardoor jij meer variatie in jouw werk kan ervaren.",
  TEXT_FOCUS_ON_SPECIFIC_SECTOR_MSG:
    "Door zich te specialiseren in specifieke sectoren zoals de kinderopvang zorg schoonmaak etc, beschikt Berenvriendjes over veel knowhow, ervaring en inzicht in de behoeften en uitdagingen binnen die branches. Hierdoor kunnen zij beter begrijpen wat er vereist is voor een succesvolle match tussen werkgevers en werknemers",
  TEXT_REGULATORY_EXPERTISE_MSG:
    "Berenvriendjes is goed op de hoogte van de specifieke regelgeving en vereisten in de kinderopvangsector en zorgsector waar de standaarden hoog zijn, zoals certificeringseisen, VOG, diploma’s, PRK en koppelingen bij organisaties voor pedagogisch medewerkers. Dit stelt ons in staat om aan de wettelijke verplichtingen te voldoen en hun klanten te adviseren over naleving van de regelgeving..",
  TEXT_ATTENTION_TO_QUALITY_MSG:
    "Berenvriendjes richt sterk op het selecteren van gekwalificeerd personeel met de juiste vaardigheden en ervaring voor de specifieke behoeften van de kinderopvangsector, zorgsector en overige branches. Dit kan leiden tot een hogere kwaliteit van geplaatste medewerkers en betere dienstverlening aan klanten.",
  TEXT_ABOUT_US_DESCRIPTION_MSG:
    "“Vertrouwen is de basis van alles wat we doen. Dat geldt ook voor onze medewerkers en partners. Wij bieden graag continuïteit en werken aan onze relaties.”,",
  TEXT_CONTACT_US_DESCRIPTION_MSG:
    "Solliciteren of heb je nog vragen? Neem dan contact op via de onderstaande mogelijkheden!",
  TEXT_AVAILABLITY_MSG:
    " “Wij zijn bereikbaar van maandag tot en met vrijdag tijdens deze uren:”,",
  TEXT_MONDAY: "Maandag",
  TEXT_TUESDAY: "Dinsdag",
  TEXT_WEDNESDAY: "Woensdag",
  TEXT_THURSDAY: "Donderdag",
  TEXT_FRIDAY: "Vrijdag",
  TEXT_WE_CAN_BE_REACHED_VIA_WHATSAPP_MSG:
    "*Wij zijn buiten kantooruren en in het weekend bereikbaar via whatsapp.",
  TEXT_ADDRESS: "Adres",
  TEXT_NO_MESSAGE: "Nog geen chat",

  TEXT_NO_CHAT_TITLE: "Momenteel heb je geen chat om te bekijken.",
  TEXT_NO_TASK_TITLE: "Momenteel heeft u geen taak om te bekijken.",

  TEXT_NO_CHAT_MESSAGES: (
    <>
      Wanneer u begint met communiceren op uw werk,
      <br /> berichten van uw organisatie worden hier weergegeven.
    </>
  ),
  TEXT_NO_TASKS: (
    <>
      Wanneer u met een baan begint,
      <br /> uw diensten zullen hier staan.
    </>
  ),
  TEXT_EXPLORE_JOBS: "Ontdek diensten",
  TEXT_EXPLORE_TALENT: "Ontdek talenten",
  TITLE_CONTACT_US_NEW_HOME: (
    <>
      Heb je een interessante vacature gezien bij een kinderdagverblijf op ons platform en ben je enthousiast om te solliciteren? We staan klaar om jouw vragen over de vacature van het kinderdagverblijf te beantwoorden en je te helpen bij de volgende stap in je carrière als freelancer. Werken bij een kinderopvang biedt jou niet alleen een leuke en uitdagende baan, maar ook de kans om bij te dragen aan de ontwikkeling van de jongste generatie, dus neem contact met ons op.
    </>
  ),
  TITLE_CHILD_CARE_BODY:
    "Werken in een kinderopvang in regio Utrecht betekent dat je dagelijks omringd wordt door de vrolijke gezichten van kinderen. Onze vacatures voor een kinderdagverblijf in de buurt van Utrecht bieden diverse functies, van pedagogisch medewerker tot groepsleider. Elke vacature voor een kinderdagverblijf in de Domstad biedt unieke voordelen, zoals de mogelijkheid om creativiteit in te zetten tijdens activiteiten en de voldoening die komt van het zien van kinderen die zich onder jouw begeleiding ontwikkelen. Bovendien werk je als zzp’er in een hecht team waar samenwerking en collegialiteit hoog in het vaandel staan. Met onze vacatures om te werken in een kinderopvang heb je niet alleen een baan, maar ook een plek waar je elke dag met plezier naartoe gaat.",
  TITLE_CHILD_CARE_BODY_TEXT:
    "Vindt direct de perfecte medewerker voor jouw dienst",

  TEXT_TELEPHONE: "Telefoon",
  TEXT_EMAIL: "E-mail",
  TEXT_WHATSAPP: "Whatsapp",
  TEXT_HOURLY_WAGE: "Uurloon",
  TEXT_JOB_TITLE: "Functie",
  TEXT_STAFF_MSG:
    "Bij Berenvriendjes richten we ons op jouw individuele behoeften en streven we ernaar maatwerkoplossingen te bieden die bij jou passen. We investeren in jouw persoonlijke groei door middel van trainingen en ontwikkelingsmogelijkheden, zodat jij je talenten optimaal kunt benutten. Daarnaast ondersteunen we je bij het vinden van een gezonde werk-privébalans, zodat je zowel op het werk als thuis goed functioneert. We evalueren regelmatig onze processen en werkwijzen en kijken samen naar mogelijke verbeterpunten. Jouw feedback is hierbij van groot belang, omdat we geloven in een cultuur van open communicatie en voortdurende verbetering. Door samen te werken aan kwaliteitsverbetering, streven we ernaar de best mogelijke werkomgeving te creëren. Ons doel is om een langdurige arbeidsrelatie op te bouwen waarin jij je thuis voelt en je kunt ontwikkelen. We willen datje met plezier en voldoening bij ons werkt, zowel nu als in de toekomst. Bij Berenvriendjes staat jouw welzijn en professionele groei centraal, en we werken er hard aan om dit te realiseren.",
  TEXT_PARTNERS_MSG:
    "Voor onze partners zijn wij geen uitzend- of detacheringsbureau. Dit biedt aanzienlijke voordelen. Dankzij onze specialisatie in pedagogiek en sectoren zoals onderwijs, zorg, schoonmaak, retail en industrie, hebben wij uitgebreide kennis, ervaring en inzicht. Onze gerichte aanpak stelt ons in staat om de specifieke behoeften en uitdagingen van deze branches effectief te matchen. Hierdoor kunnen wij snel en efficiënt de juiste kandidaten vinden die perfect passen bij de eisen en cultuur van uw organisatie. Bovendien beschikken onze medewerkers over de juiste kwalificaties en certificeringen. Dit betekent dat u zich geen zorgen hoeft te maken over hun bekwaamheid of geschiktheid. Wij zorgen ervoor dat u altijd kunt vertrouwen op competente en gekwalificeerde professionals, zodat u zich kunt concentreren op uw kernactiviteiten.",
  TEXT_DO_NOT_HAVE_AN_ACCOUNT: "Heb je geen account?",
  TEXT_REMEMBER_ME: "Onthoud me",
  TEXT_OR: "OF",
  TEXT_ONCE_YOUR_PROPOSAL_HAS_COUGHT_MSG:
    "Zodra uw voorstel de aandacht van een klant heeft getrokken, kunnen zij contact met u opnemen via ons berichtensysteem.,",
  TEXT_WELCOME_MSG:
    "We zijn blij je aan boord te hebben en bedanken je dat je ons platform hebt gekozen om aan je reis te beginnen. Laten we erin duiken!,",
  TEXT_CREATE_YOUR_ACCOUNT_LIST:
    "Maak uw account aan: ga om te beginnen naar onze website en klik op de knop 'Aanmelden'.,",
  TEXT_SETUP_YOUR_PROFILE_LIST:
    " 'Stel uw profiel in: zodra u uw account succesvol heeft aangemaakt, is het tijd om uw profiel in te stellen.',",
  TEXT_EXPLORE_PROJECT_LIST:
    "Verken projectoverzichten: onze webapp biedt een breed scala aan projecten in verschillende categorieën. Blader door het projectoverzicht om mogelijkheden te vinden die aansluiten bij uw vaardigheden en interesses.,",
  TEXT_SUBMIT_PROPOSALS_LIST:
    "'Dien voorstellen in: Wanneer u een dienst tegenkomt dat aansluit bij uw expertise, maak dan een overtuigend voorstel dat uw capaciteiten laat zien en uitlegt hoe u waarde kunt toevoegen aan het project van de klant.',",
  TEXT_COMMUNICATE_WITH_CLIENTS_LIST:
    "'Communiceer met klanten: zodra uw voorstel de aandacht van een klant heeft getrokken, kunnen zij contact met u opnemen via ons berichtensysteem.',",
  TEXT_SECURE_PAYMENT_SETUP_LIST:
    "Veilige betalingsinstellingen: Om een ​​naadloos betalingsproces te garanderen, stelt u de betaalmethode van uw voorkeur veilig in uw accountinstellingen in. ,",
  TEXT_WORK_ON_PROJECTS_LIST:
    "Werk aan de winkel: Zodra je een dienst hebt binnengehaald en de voorwaarden met de klant hebt afgesproken, is het tijd om aan de slag te gaan!,",
  TEXT_RECEIVE_FEEDBACK_AND_RATINGS_LIST:
    "Ontvang feedback en beoordelingen: feedback en beoordelingen van klanten spelen een cruciale rol bij het vestigen van uw reputatie als Berenvriendjes medewerker.,",
  TEXT_EXPLORE_ADDITIONAL_FEATURES_LIST:
    "Ontdek extra functies: naast projectoverzichten biedt onze webapp extra functies om uw freelance-ervaring te verbeteren. ,",
  TEXT_STAY_ACTIVE_AND_ENGAGED_LIST:
    " 'Blijf actief en betrokken: consistentie en proactiviteit zijn de sleutel tot succes.',",
  TEXT_WE_ARE_THRILLED_TO_HAVE_YOU_ON_BOARD:
    "We zijn blij je aan boord te hebben en bedanken je dat je ons platform hebt gekozen om aan je reis te beginnen. Laten we erin duiken!,",
  TEXT_PLEASE_ENTER_YOUR_DETAILS:
    "Vul uw gegevens in om een ​​nieuw account in te stellen.",
  TEXT_STEP: "Stap",
  TEXT_OF: "van",
  TEXT_I_ACCEPT_THE_TERMS_AND_CONDITIONS:
    "Ik accepteer de algemene voorwaarden.",
  TEXT_CHILD_DAY_CARE: "Kinderopvang",
  TEXT_HEALTH_CARE: "Gezondheidszorg",
  TEXT_OFFICES: "Kantoor",
  TEXT_IT: "IT",
  TEXT_CONSTRUCTION: "Bouw",
  TEXT_HOSPITALITY: "Hospitality",
  TEXT_CLEANING: "Schoonmaak",
  TEXT_LOGISTICS: "Logistiek",
  TEXT_SALES: "Verkoop",
  TEXT_CALL_CENTER: "Callcenter",
  TEXT_EDUCATION: "Onderwijs",
  TEXT_OTHERS: "Overig",
  TEXT_ID_MANDATORY: "ID (verplicht)",
  TEXT_CERTIFICATES_OPTIONAL: "Certificaat (optioneel)",
  TEXT_DIPLOMA_OPTIONAL: "Diploma (optioneel)",
  TEXT_INSURANCE_BLOCK_OPTIONAL: "Bedrijfsaansprakelijkheidsverzekering",
  TEXT_SCAN_BY_CAMERA: "Scannen met camera",
  TEXT_THIS_ONBOARDING_GUILD_WILL_HELP_MSG:
    "Deze handleiding helpt u om te beginnen en het maximale uit onze webapplicatie te halen",
  TEXT_DIDNT_RECEIVE: "Niet ontvangen? ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK:
    "We hebben een verificatielink naar dit e-mailadres gestuurd.",
  TEXT_WE_HAVE_SENT_RESET_PASSWORD_LINK:
    "We have sent a forgot link to this email ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK_after:
    "controleer uw inbox en verifieer uw e-mailadres om in te loggen op uw account.",
  TEXT_MARK_ALL_AS_READ: "Alles als gelezen markeren",
  TEXT_SEE_ALL_NOTIFICATIONS: "Bekijk alle meldingen",
  TEXT_INCOMPLETE: "Incompleet",
  TEXT_POSTED_ON: "Geplaatst op:",
  TEXT_PERSON: "Persoon",
  TEXT_AVATAR_REQUIREMENT: "Minimaal 500 * 500px, .png of .jpeg",
  TEXT_HAVE_QUESTIONS_WE_ARE_HERE_TO_HELP:
    "Heeft u vragen? Wij zijn er om u te helpen",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT:
    "In het Beschikbaarheids Rooster kan jij je beschikbare tijden toevoegen. Selecteer de relevante datum en stel uw beschikbaarheid in wanneer jij kan werken of niet kan werken.",
  TEXT_INVOICE_TAGLINE:
    "Genereer moeiteloos facturen voor voltooide taken die door de organisatie zijn goedgekeurd en door werknemers zijn uitgevoerd.",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT_MOBILE:
    "In het Beschikbaarheids Rooster kan jij je beschikbare tijden toevoegen. ",
  TEXT_STAY_ORGANIZED_ADD_YOUR_SCHEDULE:
    "Blijf georganiseerd – voeg uw planning toe om belangrijke data bij te houden.",
  TEXT_DECLINE_JOB_NOTE:
    "Als u een aanvraag afwijst, wordt deze dienst verwijderd.",
  TEXT_REJECT_JOB_DONE_BY_WORKER:
    "Het afwijzen van een dienst die door de werknemer is uitgevoerd, betekent dat de werknemer de dienst niet heeft voltooid. Deze wordt verwijderd.",
  TEXT_HANDLE_JOB_REQUEST: "Dienst aanvraag afhandelen",
  TEXT_STAY_ORGANIZED_EDIT_YOUR_SCHEDULE:
    "Blijf georganiseerd – bewerk uw planning om belangrijke data bij te houden.",
  TEXT_HOURS_WORKED: "Gewerkte uren",
  TEXT_HOURS_AGO: "Uren geleden",
  TEXT_SEE_MORE: "Bekijk meer...",
  TEXT_CANCEL_JOB: "Dienst annuleren",
  TEXT_DELETE_JOB: "Dienst verwijderen",
  TEXT_REPOST_JOB: "Dienst annuleren",
  TEXT_NOTHING_HERE_YET: "Hier is nog niets!",
  TEXT_FIND_JOB: "Vind een nieuwe dienst!",
  TEXT_ASK_ORGANIZATION_TO_GENRATE_INVOICE:
    "Vraag de organisatie om factuur te genereren",
  TEXT_SEE_ALL_APPLICATIONS: "Zie alle sollicitanten",

  TEXT_LAST_SEEN: "Laatst gezien",
  TEXT_LAST_SEEN_JUST_NOW: "Laatst gezien zojuist",
  TEXT_MINUTE: "minuten",
  TEXT_HOUR: "uur",
  TEXT_HOURS: "uren",
  TEXT_DAY: "dag",
  TEXT_DAYS: "dagen",
  TEXT_AGO: "geleden",
  TEXT_ONLINE: "Online",
  TEXT_TYPING: "Aan het typen",
  TEXT_TERMS_AND_CONDITIONS: "Je moet akkoord gaan met de algemene voorwaarden",
  TEXT_PASSWORD_HINT:
    "Het wachtwoord moet minimaal één kleine letter, één hoofdletter, één cijfer, één speciaal teken (bijv. !@#$%^&()-__+), en minimaal 8 tekens lang zijn.",
  TEXT_PROVIDE_PERSNAL_DETAIL: "Vul persoonlijke gegevens in.",
  TEXT_ADRESS_DETAIL: "Vul adresgegevens in.",

  TEXT_PROVIDE_BUSINESS_DETAILS: "Vul bedrijfsgegevens in.",
  TEXT_PROVIDE_BANK_DETAIL: "Vul bankgegevens in.",
  TEXT_SELECT_MAXIMUM_3_BRANCHES:
    "Selecteer maximaal 3 branches van uw bedrijf.",
  TEXT_PROVIDE_DOCUMENTATION:
    "Vul documentatie in en voor- en achterkant van uw ID is verplicht. De bestandsgrootte moet kleiner zijn dan 2 MB voor elk.",
  TEXT_PROVIDE_CONTACT_INFORMATION: "Vul contactgegevens in.",

  TEXT_LOCATION_TIP:
    "*persoon kan alleen diensten in de buurt van 30 km zien en niet meer",

  TEXT_MESSAGES_LOADING: "Berichten worden geladen...",

  BASIC_HIRE_SOMEONE: "Medewerker inhuren",
  TEXT_JOB_OFFER_REJECTION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Weet u zeker dat u het aanbod voor de functie van{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span> wilt afwijzen? Deze
      actie kan niet ongedaan worden gemaakt, en het aanbod zal worden
      afgewezen. Klik op 'Bevestigen' om af te wijzen, of 'Annuleren' om terug
      te gaan en te heroverwegen.
    </>
  ),
  TEXT_JOB_OFFER_CANCELATION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Weet u zeker dat u de uitnodiging voor de functie van{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span> wilt annuleren?
      Deze actie zal de genodigde op de hoogte stellen, en de uitnodiging zal
      worden ingetrokken. Klik op 'Bevestigen' om te annuleren, of 'Annuleren'
      om terug te gaan en te heroverwegen.
    </>
  ),
  TEXT_JOB_OFFER_CONFIRMATION: ({
    jobTitle,
    companyName,
  }: {
    jobTitle?: string;
    companyName?: string;
  }) => (
    <>
      Weet u zeker dat u het aanbod voor de functie van{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span> wilt accepteren?
      Deze actie is onomkeerbaar, en u zult officieel deel uitmaken van het team
      van <span className={styles["jobTitle"]}>{companyName}</span>. Klik op
      'Bevestigen' om te accepteren, of 'Annuleren' om terug te gaan en te
      heroverwegen.
    </>
  ),
  TEXT_STAY_ORGANIZED_MSG: "Blijf georganiseerd bericht",
  TEXT_HIRE_SOMEONE: "Medewerker inhuren!",
  TEXT_NO_JOBS: "Probeer het met andere filters of kom later nog eens terug.",

  BASIC_BLOCK_CONFIRMATION: "Weet u zeker dat u deze gebruiker wilt blokkeren?",
  BASIC_MUTE_NOTIFICATION_CONFIRMATION:
    "Weet u zeker dat u de meldingen van berichten wilt dempen?",
  BASIC_REPORT_CONFIRMATION:
    "Weet u zeker dat u deze gebruiker wilt rapporteren?",

  TEXT_SELECT_WORKER:
    "Selecteer werknemer om de diensten van de medewerker te controleren.",

  TEXT_SELECT_WORKER_MESSAGE: (
    <>
      Selecteer werknemer om de ploegendiensten van de werknemer te
      controleren.,
      <br /> Wijzig het filter om verschillende diensten te zien.
    </>
  ),

  TEXT_WORKER_TASKS_EMPTY: "Momenteel heeft de werknemer geen diensten.",

  TEXT_SELECT_WORKER_MESSAGE_EMPTY: (
    <>
      Werknemer heeft diensten,
      <br /> je kunt verschillende datum filters proberen
    </>
  ),

  TEXT_ARE_YoU_SURE_DELETE_THIS_MESSAGE:
    "Het verwijderen van een dienst heeft geen invloed op het lopende arbeidscontract. Als u het arbeidscontract wilt annuleren of voltooien, kunt u dit doen via het scherm Ingehuurde werknemer.",

  BASIC_UNBLOCK_CONFIRMATION:
    "Weet u zeker dat u deze gebruiker wilt deblokkeren?",

  BASIC_UNMUTE_NOTIFICATION_CONFIRMATION:
    "Weet je zeker dat je de melding van berichten wilt opheffen?",

  BASIC_TODAY: "Vandaag",

  TEXT_ID_BACK_MANDATORY: "ID achterkant(verplicht)",
  BASIC_EMPTY_NOTIFICATION: "Notification box is empty.",
  TEXT_ATTENTION_TO_ORGANIZATION_MSG:
    "Berenvriendjes kan een persoonlijke aanpak hanteren en maatwerkoplossingen bieden die aansluiten bij de specifieke eisen van hun klanten in de kinderopvangsector. Dit kan leiden tot meer tevredenheid bij zowel werkgevers als werknemers.",

  TEXT_WORKING_IN_CHILD_CARE: (
    <>
      Welkom bij Berenvriendjes, waar passie voor het welzijn en de ontwikkeling
      van kinderen samengaat met het vinden van de perfecte match tussen
      getalenteerde professionals en inspirerende kinderopvangcentra. Bij
      Berenvriendjes begrijpen we dat het juiste personeel het verschil maakt in
      de groei van een kind, en daarom zijn we op zoek naar een enthousiaste
      individuen om ons dynamisch team van sportbegeleiders, pedagogisch
      medewerkers en onderwijsassistenten te versterken.
      <br />
      <br />
      Als uitzendplatform zijn we trots op het samenbrengen van gekwalificeerde
      professionals met kinderopvangcentra die streven naar excellentie in hun
      dienstverlening. Of je nu een ervaren sportbegeleider bent die dol is op
      het aanmoedigen van een gezonde levensstijl, een empathische pedagogisch
      medewerker die gedreven is om een veilige en stimulerende omgeving te
      creëren, of een onderwijsassistent die zich inzet voor individuele groei,
      bij Berenvriendjes is er een plek voor jou.
      <br />
      <br />
      Wij geloven in het belang van persoonlijke aandacht en professionele
      ontwikkeling. Daarom streven we ernaar om niet alleen de behoeften van de
      kinderopvangcentra te vervullen, maar ook de carrièredoelen van onze
      medewerkers te ondersteunen. Samen bouwen we aan een gemeenschap waarin
      zowel kinderen als professionals kunnen gedijen en groeien.
      <br />
      <br />
      Wil jij deel uitmaken van ons toegewijde team en een positieve impact
      hebben op het leven van kinderen? Sluit je dan aan bij Berenvriendjes en
      ontdek de opwindende mogelijkheden die op jou wachten. Samen kunnen we de
      weg effenen voor een bloeiende professionals die hen begeleiden.
    </>
  ),
  TEXT_WORKING_IN_CHILD_CARE_ORG: (
    <div>
      <style>
        {`
          body { line-height:108%; font-family:Calibri; font-size:11pt }
          h3, p { margin:0pt 0pt 8pt }
          li { margin-top:0pt; margin-bottom:8pt }
          h3 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Calibri Light'; font-size:12pt; font-weight:normal; color:#1f4d78 }
          .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:11pt }
          .Kop3Char { font-family:'Calibri Light'; font-size:12pt; color:#1f4d78 }
        `}
      </style>

      <p>
        Berenvriendjes richt zich volledig op de naleving van wet- en
        regelgeving, zoals gecontroleerd wordt door de GGD. Dit houdt in dat de
        VOG en diploma’s handmatig door onze HR-afdeling worden gecontroleerd om
        te voldoen aan alle voorschriften.
      </p>
      <p>
        Ons proces onderscheidt zich van andere platforms doordat wij de
        koppelverzoeken vanuit onze HR-afdeling versturen voor de
        uitzendkrachten die bij jullie kinderopvangorganisatie worden ingezet.
      </p>
      <p>
        Dit garandeert dat het proces onder controle blijft bij Berenvriendjes,
        in tegenstelling tot andere platforms waar de verantwoordelijkheid bij
        de ZZP'er en de organisatie ligt, wat vaak tot geschillen leidt.
      </p>
      <p>
        <strong
          style={{
            color: "rgb(114 143 242)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            fontSize: 24,
            fontFamily: "Open Sans",
          }}
        >
          Dit maakt Berenvriendjes UNIEK!{" "}
        </strong>
      </p>
      <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li>
          <strong style={{ fontFamily: "Open Sans", fontSize: "14pt" }}>
            Vaste tarieven
          </strong>
          <ul
            style={{
              marginRight: "0pt",
              marginLeft: "0pt",
              paddingLeft: "15pt",
              listStyleType: "disc",
              paddingTop: "8pt",
            }}
          >
            <span
              style={{
                lineHeight: "108%",
                fontFamily: "Open Sans",
                fontSize: "12pt",
              }}
            >
              In tegenstelling tot andere platforms, vragen wij geen fee per uur
              bij een organisatie maar hanteren wij een vast tarief zonder
              bijkomende kosten.
            </span>
          </ul>
        </li>
        <li>
          <strong style={{ fontFamily: "Open Sans", fontSize: "14pt" }}>
            Uitgebreide service
          </strong>
        </li>
      </ol>
      <p
        style={{
          fontFamily: "Open Sans",
          paddingLeft: "15pt",
          fontSize: "12pt",
        }}
      >
        Wij bieden standaard de service en ondersteuning aan van een
        uitzendbureau.
      </p>
      <p>
        <strong style={{ fontFamily: "Open Sans", fontSize: "14pt" }}>
          Persoonlijke planners
        </strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            lineHeight: "108%",
            paddingLeft: "15pt",
            fontFamily: "Open Sans",
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Open Sans",
              fontSize: "12pt",
            }}
          >
            Samen met onze planners bepaal je de uitvraag. Je vertelt ons
            hoeveel medewerkers je nodig hebt en welke diploma’s/certificaten ze
            moeten bezitten.
          </span>
        </li>
      </ul>
      <p>
        <strong style={{ fontFamily: "Open Sans", fontSize: "14pt" }}>
          Flexibele aanvraagmogelijkheden
        </strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            lineHeight: "108%",
            paddingLeft: "15pt",
            fontFamily: "Open Sans",
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Open Sans",
              fontSize: "12pt",
            }}
          >
            De aanvragen van diensten worden binnen 24 uur na aanvraag
            ingepland. De aanvragen kunnen via onze webapp, WhatsApp,
            telefonisch en per e-mail worden ingediend. We proberen de dienst zo
            snel mogelijk in te plannen, afhankelijk van het communicatiemiddel
            dat de organisatie het handigst vindt.
          </span>
        </li>
      </ul>
      <p>
        <strong style={{ fontFamily: "Open Sans", fontSize: "14pt" }}>
          24/7 Bereikbaarheid
        </strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            lineHeight: "108%",
            paddingLeft: "15pt",
            fontFamily: "Open Sans",
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Open Sans",
              fontSize: "12pt",
            }}
          >
            Voor spoedgevallen zijn we 24/7 bereikbaar via WhatsApp!{" "}
          </span>
        </li>
      </ul>
      <p>
        <strong style={{ fontFamily: "Open Sans", fontSize: "14pt" }}>
          Zelf diensten aanmaken via ons platform
        </strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            lineHeight: "108%",
            paddingLeft: "15pt",
            fontSize: "12pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontSize: "12pt",
              fontFamily: "Open Sans",
            }}
          >
            Indien je geen gebruik wilt maken van de service, kun je nog steeds
            zoals op andere platformen jouw dienst zelf live zetten via ons
            platform, waardoor werknemers van Berenvriendjes de shift kunnen
            invullen.
          </span>
        </li>
      </ul>
      <p style={{ marginLeft: "72pt" }}>&#xa0;</p>
      <p style={{ fontFamily: "Open Sans" }}>
        Berenvriendjes biedt hiermee een betrouwbaar, efficiënt en
        gebruiksvriendelijk alternatief voor andere soortgelijke platforms, met
        een sterke focus op naleving van regelgeving en uitgebreide
        ondersteuning voor kinderopvangorganisaties.
      </p>
      <p style={{ fontFamily: "Open Sans" }}>
        Mocht u meer informatie willen ontvangen over de werkwijze en proces,
        aarzel niet om contact met ons op te nemen.
      </p>
    </div>
  ),


  TEXT_PAGE_01_DESCRIPTION1: (
    <>
      Bij Berenvriendjes bieden wij vacatures aan voor in de kinderopvang in Utrecht en omstreken. Als jij een passie hebt voor het werken met kinderen en een positieve impact wilt maken op hun groei en welzijn, dan hebben wij interessante vacatures voor jou beschikbaar. Bij ons kun je rekenen op werkomgevingen waar persoonlijke ontwikkeling en plezier centraal staan.
    </>
  ),
  TEXT_PAGE_01_DESCRIPTION2: (
    <>
      Werken in een kinderopvang in regio Utrecht betekent dat je dagelijks omringd wordt door de vrolijke gezichten van kinderen. Onze vacatures voor een kinderdagverblijf in de buurt van Utrecht bieden diverse functies, van pedagogisch medewerker tot groepsleider. Elke vacature voor een kinderdagverblijf in de Domstad biedt unieke voordelen, zoals de mogelijkheid om creativiteit in te zetten tijdens activiteiten en de voldoening die komt van het zien van kinderen die zich onder jouw begeleiding ontwikkelen. Bovendien werk je in loondienst en kom je terecht in een hecht team waar samenwerking en collegialiteit hoog in het vaandel staan. Met onze vacatures om te werken in een kinderopvang heb je niet alleen een baan, maar ook een plek waar je elke dag met plezier naartoe gaat. Zelfs als je aan de slag wilt als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er in de kinderopvang</a>, vind je bij ons de ideale vacature.
    </>
  ),
  
  TEXT_PAGE_01_DESCRIPTION3: (
    <>
      Heb je een interessante vacature gezien bij een kinderdagverblijf op <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a> en ben je enthousiast om te solliciteren? We staan klaar om jouw vragen over de vacature van het kinderdagverblijf te beantwoorden en je te helpen bij de volgende stap in je carrière in loondienst of als <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a>. Werken bij een kinderopvang biedt jou niet alleen een leuke, flexibele en uitdagende baan, maar ook de kans om bij te dragen aan de ontwikkeling van de jongste generatie, dus neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op. 
    </>
  ),


  TEXT_PAGE_02_DESCRIPTION1: (
    <>
      Wil je freelancer bij een kinderopvang in de regio van Utrecht worden? Bij Berenvriendjes kunnen we je helpen om deze droom werkelijkheid te maken. Als freelancer bij een kinderopvang heb je de vrijheid om je eigen uren te bepalen en te werken in een omgeving waar jouw passie voor kinderen volledig tot zijn recht komt. Bij ons krijg je de mogelijkheid om je talenten en vaardigheden te benutten in een warme en stimulerende omgeving. 
    </>
  ),
  TEXT_PAGE_02_DESCRIPTION2: (
    <>
     Als freelancer bij een kinderopvang in de regio van Utrecht geniet je van talloze voordelen. Ten eerste biedt freelancen je de flexibiliteit om je eigen werktijden te bepalen. Dit betekent dat je een perfecte balans kunt vinden tussen werk en privéleven, waardoor je optimaal kunt genieten van beide werelden. Daarnaast kun je als freelancer bij een kinderopvang verschillende ervaringen opdoen door in diverse instellingen in Utrecht te werken, wat je professionele leven verrijkt en je een brede kennis en ervaring binnen de kinderopvangsector oplevert. Het werken in verschillende omgevingen rondom de Domstad als  <a href="https://berenvriendjes.nl/zzp-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er</a> geeft je ook de mogelijkheid om een groot netwerk op te bouwen, wat je toekomstige carrière ten goede komt.
    </>
  ),
  TEXT_PAGE_02_DESCRIPTION3: (
    <>
      Heb je nog vragen of wil je meer weten over de mogelijkheden om freelancer bij een kinderopvang te worden? Neem vandaag nog <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op en ontdek hoe jij als freelancer bij een kinderopvang in Utrecht en omstreken aan de slag kunt gaan en het verschil kunt maken in het leven van kinderen! Bekijk ook zeker de <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> op ons platform om alvast een beeld te krijgen van de mogelijkheden.
    </>
  ),


  TEXT_PAGE_03_DESCRIPTION1: (
    <>
      Een zzp’er worden bij een kinderopvang in Utrecht is mogelijk via ons. Als zzp’er werk je op freelance basis en bied je je diensten aan verschillende kinderopvangorganisaties. Dit betekent dat je de vrijheid hebt om je eigen uren te bepalen en je eigen opdrachten te kiezen. Wij bij Berenvriendjes helpen je graag verder om jouw carrière in de kinderopvang te starten of uit te breiden. 
    </>
  ),
  TEXT_PAGE_03_DESCRIPTION2: (
    <>
    Als zzp’er bij een kinderopvang in Utrecht en omstreken heb je de kans om je passie voor het werken met kinderen te combineren met de voordelen van zelfstandig ondernemerschap. Werken als zzp’er bij een kinderopvang biedt diverse voordelen. Allereerst kun je als <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a> kiezen voor opdrachten die perfect bij jouw specialisaties en interesses passen. Dit kan variëren van het verzorgen van baby’s en peuters tot het begeleiden van oudere kinderen bij educatieve activiteiten. Werken als zzp’er in de kinderopvang betekent ook dat je kunt genieten van een gevarieerde en flexibele werkomgeving, omdat je de mogelijkheid hebt om bij verschillende kinderopvangcentra rondom de Domstad en gezinnen aan de slag te gaan. Dit zorgt voor een dynamische en uitdagende werkervaring.
    </>
  ),
  TEXT_PAGE_03_DESCRIPTION3: (
    <>
      Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op voor meer informatie over de <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>beschikbare vacatures</a> en mogelijkheden. Onze expertise en uitgebreide netwerk zorgen ervoor dat jij snel en effectief aan de slag kunt als zzp’er in de kinderopvang. Ontdek hoe jij als zzp’er via <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a> een waardevolle bijdrage kunt leveren aan de zorg en ontwikkeling van kinderen.
    </>
  ),

  
  TEXT_PAGE_04_DESCRIPTION1: (
    <>
      Kinderopvang vacatures in de buurt van Den Haag vind je gemakkelijk via ons platform. Ook bieden wij bij Berenvriendjes een uitgebreid overzicht van beschikbare functies in verschillende kinderopvangcentrums door de hele stad en daarbuiten. Wij helpen je bij het vinden van de juiste vacature voor een kinderdagverblijf die aansluit bij jouw wensen en kwalificaties. 
    </>
  ),
  TEXT_PAGE_04_DESCRIPTION2: (
    <>
     Als je op zoek bent naar de leukste vacatures om te <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> bij een kinderopvang, dan ben je bij ons aan het juiste adres. Onze expertise en uitgebreide netwerk zorgen ervoor dat jij snel en effectief aan de slag kunt als zzp’er in de kinderopvang. Ontdek hoe jij als zzp’er via <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> bij een kinderopvang, dan ben je bij ons aan het juiste adres. Ons platform biedt een breed scala aan vacatures per functie bij een kinderdagverblijf, variërend van pedagogisch medewerkers tot groepsleiders, zowel in loondienst als voor zzp'ers. Werken bij een kinderopvang betekent dat je dagelijks een positieve impact kunt maken op het leven van kinderen, terwijl je werkt in een dynamische, flexibele en ondersteunende omgeving. Of je nu een doorgewinterde professional bent of net je carrière begint als freelancer, wij hebben de juiste vacatures voor jou om te werken in een kinderdagverblijf in de Hofstad en omstreken.
    </>
  ),
  TEXT_PAGE_04_DESCRIPTION3: (
    <>
     Vind de juiste kinderdagverblijf vacature via ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a>. Wij bieden persoonlijke begeleiding en ondersteuning <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>via ons platform</a> om ervoor te zorgen dat je in de juiste kinderopvang gaat werken. Bekijk de actuele vacatures op ons platform en solliciteer direct om jouw volgende stap in de kinderopvang te zetten. Heb je vragen of wil je meer informatie? Neem dan <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op. Wij staan klaar om je te helpen bij elke stap van het proces.  
    </>
  ),


  TEXT_PAGE_05_DESCRIPTION1: (
    <>
     Om freelancer bij een kinderopvang in Den Haag en omstreken te worden kun je je aanmelden bij Berenvriendjes en direct aan de slag gaan. Als freelancer bij een kinderopvang heb je de mogelijkheid om flexibel te werken, je eigen opdrachten te kiezen en je passie voor kinderen volledig tot zijn recht te laten komen. Op ons platform vind je een breed aanbod aan mogelijkheden die perfect aansluiten bij jouw wensen en kwaliteiten.
    </>
  ),
  TEXT_PAGE_05_DESCRIPTION2: (
    <>
      Werken als freelancer bij een kinderopvang biedt jou de kans om verschillende functies en omgevingen uit te proberen. Dit is een geweldige manier om waardevolle ervaring op te doen en te ontdekken welke aspecten van de kinderopvang jou het meest aanspreken. Als je een passie hebt voor alles wat met <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> te maken heeft, dan is werken bij een kinderopvang in de buurt van Den Haag echt iets voor jou. Via ons platform kun je verschillende <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> vinden, banen proberen en een diverse werkervaring opbouwen, wat je zowel professioneel als persoonlijk verrijkt. Dat is nou net hetgeen wat je wil als freelancer in de Hofstad.
    </>
  ),
  TEXT_PAGE_05_DESCRIPTION3: (
    <>
     Meld je vandaag nog aan op ons platform. Hier vind je de meest actuele en interessante opdrachten in regio Den Haag die passen bij jouw vaardigheden en interesses. Heb je vragen of wil je meer informatie? Neem dan gerust <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op via e-mail. Wij als <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> staan klaar om je te helpen en te begeleiden bij elke stap van je freelancecarrière in de kinderopvang. 
    </>
  ),


  TEXT_PAGE_06_DESCRIPTION1: (
    <>
    Berenvriendjes biedt als uitzendbureau een breed scala aan medewerkers in de kinderopvang in Den Haag en omstreken. Of je nu op zoek bent naar een medewerker die graag in loondienst komt of een flexibele freelancer, ons uitzendbureau heeft de perfecte match voor jou. Met ons uitgebreide netwerk van pedagogische medewerkers die werken in verschillende kinderopvangcentra in regio Den Haag, kunnen we je helpen om de ideale kandidaat te vinden die aansluit op jouw wensen.
    </>
  ),
  TEXT_PAGE_06_DESCRIPTION2: (
    <>
      De zoektocht naar een medewerker in de kinderopvang kan lastig zijn, maar wij proberen als uitzendbureau iedereen te helpen hiermee. Wij hebben vele kandidaten beschikbaar in en rondom de Hofstad. Dit zijn kandidaten die zowel in loondienst of als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp'er</a> zzp’er willen werken. Of je nu op zoek bent naar een ervaren professional of iemand met wat minder ervaring, wij hebben een geschikte kandidaat voor jou. Ons platform biedt de mogelijkheid om snel en eenvoudig de beste match in de kinderopvang te vinden. Dit resulteert erin dat je binnen een korte tijd het personeelstekort op jouw kinderdagverblijf kunt oplossen door iemand in te schakelen die wilt <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> én woonachtig is in de omgeving. We hebben al vele kinderopvangorganisaties geholpen om een medewerker in de kinderopvang te vinden, en we staan klaar om ook jouw organisatie te helpen.
    </>
  ),
  TEXT_PAGE_06_DESCRIPTION3: (
    <>
     Ons platform is gebruiksvriendelijk en efficiënt, waardoor je snel toegang hebt om de kandidaten voor jouw <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>nieuwste vacatures</a> te bekijken. Neem de stap en ontdek de mogelijkheden die wij te bieden hebben. Samen zorgen we ervoor dat jouw kinderopvang rondom Den Haag wordt voorzien van een behulpzame en prettige medewerker. Neem dus snel    <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons </a> op.
    </>
  ),


  TEXT_PAGE_07_DESCRIPTION1: (
    <>
     Als zzp’er in de kinderopvang in Den Haag en omstreken kun je een flexibele carrière tegemoet gaan. Bij Berenvriendjes kun je aan de slag in een omgeving waar jouw passie voor kinderen volledig tot zijn recht komt. Of je nu al jaren ervaring hebt of net begint, wij helpen je om jouw ideale opdrachten te vinden en te profiteren van de vrijheid die het zzp’er zijn biedt.
    </>
  ),
  TEXT_PAGE_07_DESCRIPTION2: (
    <>
     Er zijn talloze voordelen aan het werken als zzp’er in de kinderopvang in de regio Den Haag. Je kunt je eigen werktijden bepalen en kiezen welke opdrachten het beste bij jou passen. Dit geeft je de mogelijkheid om een goede balans te vinden tussen werk en privéleven. Zo kun jij je concentreren op wat echt belangrijk is: het bieden van de beste zorg als zzp’er en het    <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a>  in een kinderopvang.
    </>
  ),
  TEXT_PAGE_07_DESCRIPTION3: (
    <>
     Meld je vandaag nog aan en ontdek hoe wij je kunnen helpen met onze leuke    <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures.</a>  Profiteer van de vele mogelijkheden die deze vorm van werken biedt. Onze expertise, ondersteuning en    <a href="https://www.berenvriendjes.nl/about" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werkwijze</a> zorgen ervoor dat jij snel en moeiteloos aan de slag kunt in Den Haag of omstreken. 
    </>
  ),


  TEXT_PAGE_08_DESCRIPTION1: (
    <>
    Werken met kinderen in regio Den Haag is een ongelooflijk leuke en bevredigende ervaring. Bij Berenvriendjes kun je jouw passie voor het begeleiden en ondersteunen van kinderen tot uiting laten komen in een dynamische en stimulerende omgeving. Den Haag, met zijn diverse en levendige gemeenschap, biedt een geweldige omgeving om te werken met kinderen, waarbij elke dag nieuwe uitdagingen en vreugde brengt.
    </>
  ),
  TEXT_PAGE_08_DESCRIPTION2: (
    <>
     Werken met kinderen is niet alleen leuk, maar ook van groot belang. Of je nu kiest voor een positie in loondienst of een    <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancepositie,</a> het werken met kinderen biedt een unieke kans om een positieve invloed uit te oefenen op de jongste generaties. Als professional in de kinderopvang ben je een rolmodel voor de kinderen en speel je een belangrijke rol in hun ontwikkeling. Ouders vertrouwen erop dat jij helpt bij de opvoeding en de motorische, cognitieve en sociale vaardigheden van hun kinderen bevordert. Het ontwikkelen van een veilige en stimulerende omgeving hier in regio Den Haag is belangrijk voor hun groei en welzijn. Daarnaast leer je kinderen belangrijke levensvaardigheden, zoals samenwerken, delen en communiceren. Jouw inzet draagt bij aan het leggen van een sterke basis voor hun toekomst.
    </>
  ),
  TEXT_PAGE_08_DESCRIPTION3: (
    <>
     Ben je enthousiast om te werken met kinderen in regio Den Haag en ben je benieuwd naar de    <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> die wij bieden? Neem dan    <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}> contact met ons</a> op voor meer informatie over de mogelijkheden. Bij ons    <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> kun je rekenen op een ondersteunende werkomgeving waar jouw werk echt gewaardeerd wordt. We staan klaar om je te helpen bij elke stap op weg naar jouw nieuwe carrière!
    </>
  ),  


  TEXT_PAGE_09_DESCRIPTION1: (
    <>
      Vacatures voor een kinderopvang in regio Amsterdam vind je eenvoudig via ons platform. Wij bij Berenvriendjes bieden een breed scala aan vacatures voor verschillende kinderopvangcentrums. Ons doel is om jou te helpen een kinderopvang te vinden in Amsterdam en omstreken waar je jouw passie voor het werken met kinderen kunt inzetten en bijdragen aan hun ontwikkeling en welzijn.
    </>
  ),
  TEXT_PAGE_09_DESCRIPTION2: (
    <>
     Reageren op een vacature voor een kinderdagverblijf in de omgeving van Amsterdam is nog nooit zo eenvoudig geweest. Via ons platform kun je snel en gemakkelijk solliciteren op de posities die bij jou passen. Of je nu als <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a>  wilt werken of op zoek bent naar een flexibel contract, wij bieden beide mogelijkheden. Werken in loondienst of als <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp'er</a> biedt je de flexibiliteit om je eigen werktijden te bepalen en diverse ervaringen op te doen bij verschillende kinderopvangcentrums. Dit kan je professionele netwerk uitbreiden en je vaardigheden verder ontwikkelen. Aan de andere kant biedt een aanstelling in loondienst je meer stabiliteit en zekerheid, met een vast uurloon en vaak ook secundaire arbeidsvoorwaarden, zoals vakantiedagen en pensioenopbouw. Beide opties hebben hun eigen voordelen en via ons platform kun je de keuze maken voor de vacature van een kinderdagverblijf die het beste bij jouw situatie en voorkeuren past.
    </>
  ),
  TEXT_PAGE_09_DESCRIPTION3: (
    <>
     Meld je aan op <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a> en bekijk de vele vacatures voor een kinderdagverblijf die wij te bieden hebben in regio Amsterdam. Ons platform is gebruiksvriendelijk en helpt je snel de kinderopvang te vinden die bij jou past en waar je wil werken. Heb je vragen of wil je meer informatie? Neem dan <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op via onder andere e-mail. Ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> staat klaar om je te ondersteunen en te begeleiden in je zoektocht naar de perfecte vacature in een kinderdagverblijf. 
    </>
  ),  


  TEXT_PAGE_10_DESCRIPTION1: (
    <>
      Een freelancer in de kinderopvang in regio Amsterdam worden kan bij Berenvriendjes, want wij hebben vele opdrachten beschikbaar voor freelancers. De hoofdstad biedt talloze mogelijkheden voor professionals die graag met kinderen aan de slag en hun talenten willen inzetten om een verschil te maken in hun leven. Ons platform is ontworpen om jou te helpen de perfecte freelance positie te vinden in de kinderopvangsector.
    </>
  ),
  TEXT_PAGE_10_DESCRIPTION2: (
    <>
     In een grote stad zoals Amsterdam is de vraag naar werknemers bij een kinderopvang hoog, wat betekent dat er veel kansen zijn voor jou als freelancer in de kinderopvang. Amsterdam en omgeving heeft een groot aantal kinderopvangcentrums, variërend van kleine buurtcentra tot grote, professionele instellingen. Deze diversiteit zorgt ervoor dat je altijd een plek kunt vinden die bij jou past. Via ons platform bieden wij vele <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> aan die speciaal gericht zijn op freelancers. Of je nu liever fulltime werkt of op zoek bent naar flexibele uren, er is altijd een geschikte positie te vinden via ons <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau.</a> Door de constante vraag naar gekwalificeerde werknemers in de kinderopvang, zijn er altijd nieuwe kansen om aan de slag te gaan.
    </>
  ),
  TEXT_PAGE_10_DESCRIPTION3: (
    <>
     Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op voor meer informatie over ons platform en de beschikbare functies in en rondom Amsterdam. Wij helpen je graag om de juiste positie te vinden die aansluit bij jouw wensen en kwalificaties. Ons team staat klaar om al je vragen te beantwoorden en je te begeleiden bij elke stap van het proces. 
    </>
  ),  



  TEXT_PAGE_11_DESCRIPTION1: (
    <>
      Als uitzendbureau voor een kinderopvang in de buurt van Amsterdam helpen wij jou de perfecte kandidaat vinden voor een opening bij jouw kinderopvang. Via Berenvriendjes kun je eenvoudig een leuke en passende baan in de kinderopvangsector aanbieden. Ons uitzendbureau biedt een breed scala aan kandidaten die aansluiten bij jouw wensen en kwalificaties.
    </>
  ),
  TEXT_PAGE_11_DESCRIPTION2: (
    <>
      Een uitzendbureau inschakelen biedt jou vele voordelen voor je kinderopvang. Allereerst nemen wij de volledige leiding in het sollicitatieproces, zodat jij je kunt concentreren op wat echt belangrijk is: het zorgen voor en begeleiden van kinderen. Wij luisteren naar jouw wensen en behoeften en verwerken deze in een of meerdere <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a>. Daarnaast matchen we de juiste kandidaten met de opdrachtgevers. Dit betekent dat je altijd zeker bent van een kandidaat die perfect bij jou opening past. Je hebt de keuze om een <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp'er</a> of iemand die graag in loondienst werkt in te zetten. Met ons uitgebreide netwerk kunnen we snel en eenvoudig een geschikte kandidaat koppelen. Onze expertise en ervaring zorgen ervoor dat je zonder stress en met vertrouwen kunt bouwen op ons. Door gebruik te maken van ons uitzendbureau bespaar je tijd en moeite, en vergroot je je kansen op het vinden van een ideale kandidaat in de kinderopvang ergens in of rondom de hoofdstad.
    </>
  ),
  TEXT_PAGE_11_DESCRIPTION3: (
    <>
     Bij ons geniet je van de vele voordelen die het inschakelen van een uitzendbureau biedt. Wij helpen je bij elke stap van het proces en zorgen ervoor dat je snel en gemakkelijk aan een kandidaat vindt in de kinderopvangsector. Heb je vragen of wil je meer informatie? Neem dan gerust <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>. Ontdek hoe wij jou kunnen helpen om de perfecte medewerker in de kinderopvang te vinden in de regio Amsterdam en meld je nu aan om te genieten van alle voordelen die wij te bieden hebben.
    </>
  ),  


  TEXT_PAGE_12_DESCRIPTION1: (
    <>
      Als zzp’er bij een kinderopvang in Amsterdam en omgeving heb je de flexibiliteit om eenvoudig van job te wisselen als het niet bevalt. Dit biedt een enorme vrijheid en de mogelijkheid om verschillende werkplekken en werkomgevingen te ervaren. Zo kun je via Berenvriendjes ontdekken wat het beste bij jou past en waar je je talenten het beste kunt inzetten. 
    </>
  ),
  TEXT_PAGE_12_DESCRIPTION2: (
    <>
      Werken als zzp’er in de kinderopvang in onze hoofdstad biedt vele voordelen. Ten eerste heb je als zzp’er de vrijheid om je eigen werktijden en opdrachten te kiezen, wat een ideale balans tussen werk en privéleven mogelijk maakt. In een stad als Amsterdam, waar de vraag naar een kinderopvang groot is, zijn er altijd veel mogelijkheden voor <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancers</a>. Dit betekent dat je kunt genieten van een stabiele stroom aan opdrachten en de flexibiliteit hebt om te kiezen waar en wanneer je werkt. Daarnaast geeft het werken als zzp’er in de kinderopvang je de kans om een breed netwerk op te bouwen en waardevolle ervaringen op te doen in verschillende kinderopvangcentra. Dit kan je carrière via ons <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a>verder versterken en nieuwe deuren openen in regio Amsterdam. 
    </>
  ),
  TEXT_PAGE_12_DESCRIPTION3: (
    <>
     Meld je aan bij ons platform en ontdek de vele <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> die wij te bieden hebben in de regio van Amsterdam. Wij staan klaar om je te helpen bij elke stap van het proces en zorgen ervoor dat je snel aan de slag kunt in de kinderopvangsector. Neem vandaag nog  <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op en start jouw carrière als zzp’er in de kinderopvang!
    </>
  ),  



  TEXT_PAGE_13_DESCRIPTION1: (
    <>
      Vacatures voor een kinderopvang in regio Rotterdam vind je eenvoudig via ons. Wij bieden een breed scala aan mogelijkheden in de regio Rotterdam, zodat je altijd een passende functie kunt vinden die aansluit bij jouw wensen en kwalificaties. Door ons uitgebreide netwerk bij Berenvriendjes in de kinderopvangsector kunnen wij je begeleiden bij elke stap van het proces.
    </>
  ),
  TEXT_PAGE_13_DESCRIPTION2: (
    <>
      Werken in een kinderopvang in en rondom de havenstad betekent dat je een positieve bijdrage levert aan de ontwikkeling en het welzijn van kinderen. Ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> biedt een breed aanbod aan vacatures, van pedagogisch medewerkers tot leidinggevende functies bij een kinderdagverblijf. Zowel als <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a> als in loondienst. Met onze kennis en expertise van een kinderopvang helpen wij je om de juiste vacature te vinden en om uiteindelijk in een kinderdagverblijf te werken. Wij begrijpen dat elke professional unieke vaardigheden en voorkeuren heeft, en daarom bieden wij maatwerkoplossingen om ervoor te zorgen dat je de perfecte match vindt. Door onze begeleiding en ondersteuning kun je met vertrouwen solliciteren op een vacature binnen een kinderdagverblijf in Rotterdam en omstreken en uiteindelijk <a href="https://berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}> werken met kinderen.</a>
    </>
  ),
  TEXT_PAGE_13_DESCRIPTION3: (
    <>
    <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a>is ontworpen om jou te helpen bij het vinden van de ideale vacature in de kinderopvangsector. Of je nu een doorgewinterde professional bent, een <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er</a>  bent of net je carrière begint, wij staan klaar om je te ondersteunen en te begeleiden bij elke stap. Meld je vandaag nog aan door <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>op te nemen en ontdek de vele vacatures om te werken bij een kinderdagverblijf. 
    </>
  ),  

  TEXT_PAGE_14_DESCRIPTION1: (
    <>
      Freelancer zijn bij een kinderopvang in regio Rotterdam biedt jou de flexibiliteit en vrijheid om eenvoudig aan een baan te komen via Berenvriendjes. Als freelancer kun je zelf bepalen waar en wanneer je werkt, wat een ideale balans creëert tussen werk en privéleven. In een grote stad als Rotterdam zijn er tal van mogelijkheden en vacatures in de kinderopvangsector.
    </>
  ),
  TEXT_PAGE_14_DESCRIPTION2: (
    <>
      De mogelijkheden voor een freelancer in de kinderopvang zijn eindeloos in Rotterdam en omstreken. Door de vele vacatures heb je als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er </a> zelf de keuze om te bepalen waar je wilt werken. Het voordeel van een grote stad als Rotterdam is het brede aanbod aan kinderopvangcentra, variërend van kleinschalige opvang tot grotere instellingen. Hierdoor kun je eenvoudig van werkplek wisselen als je behoefte hebt aan een nieuwe uitdaging of als een bepaalde plek niet aan je verwachtingen voldoet. Deze flexibiliteit van ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> stelt je in staat om diverse ervaringen op te doen en je professionele netwerk uit te breiden, wat je carrière ten goede komt.
    </>
  ),
  TEXT_PAGE_14_DESCRIPTION3: (
    <>
      Door je aan te melden bij ons, krijg je toegang tot een breed scala aan <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a>en de mogelijkheid om snel en eenvoudig aan de slag te gaan. Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op via e-mail om je aan te melden om met <a href="https://www.berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>kinderen te werken</a>in de havenstad. Ontdek hoe wij jou kunnen helpen om een succesvolle carrière als freelancer in de kinderopvang te starten. 
    </>
  ), 
  
  
  TEXT_PAGE_15_DESCRIPTION1: (
    <>
      Als uitzendbureau voor een kinderopvang in regio Rotterdam helpen wij bij Berenvriendjes mensen aan een baan in de kinderopvangsector. Ons doel is om zowel kinderopvangcentrums als werkzoekenden te ondersteunen door de juiste match te vinden. Ons uitzendbureau heeft de expertise en het netwerk om jou te helpen.
    </>
  ),
  TEXT_PAGE_15_DESCRIPTION2: (
    <>
      Als uitzendbureau in de kinderopvang bieden wij opvangcentrums in en rondom de havenstad onze expertise aan door gekwalificeerd personeel aan te leveren. Wij begrijpen de unieke behoeften van kinderopvangcentrums en zorgen ervoor dat de mensen die je via ons platform kan aannemen, perfect aansluiten bij jouw behoeften. Of het nu gaat om <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancers</a> of langdurige samenwerkingen, wij leveren professionals die enthousiast en bekwaam zijn. Wanneer je op zoek bent naar mensen die willen <a href="https://www.berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a>in Rotterdam en omstreken, dan gaan wij graag voor je op zoek. Via ons platform kun je jouw <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacature in de kinderopvang</a>plaatsen. Het maakt niet uit of dit voor een fulltimepositie of een korte opdracht is, wij vinden een geschikte kandidaat.
    </>
  ),
  TEXT_PAGE_15_DESCRIPTION3: (
    <>
      Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>   op om meer te weten te komen. Ons team staat klaar om al je vragen te beantwoorden en je te begeleiden in je zoektocht naar de perfecte baan ergens in Rotterdam. Samen kunnen we de juiste stappen zetten om jouw doelen te bereiken. Ontdek vandaag nog hoe wij jou kunnen helpen als uitzendbureau in de kinderopvangsector.
    </>
  ), 


  TEXT_PAGE_16_DESCRIPTION1: (
    <>
      Werken met kinderen in Rotterdam en omgeving geeft je een ongekende energie en voldoening. Elke dag biedt nieuwe avonturen en uitdagingen, en het werken met kinderen kan jouw leven verrijken op manieren die je je misschien niet eens kunt voorstellen. Via Berenvriendjes kom je op vele opties terecht om te werken.
    </>
  ),
  TEXT_PAGE_16_DESCRIPTION2: (
    <>
     Wanneer je kiest voor werken met kinderen, ontdek je al snel hoeveel je van hen kunt leren. Kinderen bieden een frisse blik op de wereld en door met hen te werken, krijg je een dieper begrip van hun behoeften, gedragingen en manieren van denken. Rotterdam, met zijn rijke verscheidenheid aan culturen en etniciteiten, geeft je de unieke kans om te leren omgaan met verschillende achtergronden en opvoedstijlen. Dit verrijkt niet alleen je professionele vaardigheden, maar helpt je ook om een breder perspectief op de wereld te ontwikkelen, wat je persoonlijke groei en begrip van anderen ten goede komt. Via ons  <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}> uitzendbureau</a> kun je een kinderopvang vinden door heel de regio Rotterdam. Hiervoor staan diverse  <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> open op ons platform.
    </>
  ),
  TEXT_PAGE_16_DESCRIPTION3: (
    <>
      Ben je klaar om te starten met werken met kinderen in regio Rotterdam? Neem dan <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op om de perfecte job te vinden die bij jou past. Of je nu op zoek bent naar een baan in loondienst of als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er</a> aan de slag te gaan, wij staan klaar om je te ondersteunen. 
    </>
  ), 



  TEXT_PAGE_17_DESCRIPTION1: (
    <>
      Wordt zzp’er in de kinderopvang in Rotterdam of omgeving en ontdek de vele mogelijkheden die deze stad te bieden heeft via Berenvriendjes. Er is een enorme vraag naar gekwalificeerde kinderopvangprofessionals, en als zzp’er kun je genieten van de vrijheid en flexibiliteit om je eigen opdrachten te kiezen. Of je nu net bent afgestudeerd of op zoek bent naar een nieuwe uitdaging, de kinderopvangsector biedt volop kansen voor jou.
    </>
  ),
  TEXT_PAGE_17_DESCRIPTION2: (
    <>
      Zzp’er worden in de kinderopvang brengt talloze voordelen met zich mee. Ten eerste heb je de vrijheid om je eigen werktijden en opdrachten te bepalen. Als je net bent afgestudeerd of op zoek bent naar een carrièreswitch, biedt de kinderopvangbranche in regio Rotterdam veel werkgelegenheid. Je kunt verschillende kinderopvangcentra verkennen en werken op locaties die bij jou passen, wat je professionele groei en netwerk aanzienlijk kan vergroten. Daarnaast geeft het <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancen</a> zijn je de mogelijkheid om waardevolle ervaring op te doen in diverse omgevingen. Je ontwikkelt niet alleen je vaardigheden, maar bouwt ook aan een veelzijdige carrière. Het <a href="https://www.berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a>werken met kinderen in een grote stad als Rotterdam stelt je bloot aan een breed scala aan culturele en sociale dynamieken. Dit komt je professionele en persoonlijke ontwikkeling ten goede. Geniet van de voordelen van flexibiliteit, diversiteit en de mogelijkheid om jezelf voortdurend uit te dagen en te groeien, en bekijk <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>onze vacatures.</a>
    </>
  ),
  TEXT_PAGE_17_DESCRIPTION3: (
    <>
      Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op om de leukste en meest geschikte opdrachten te vinden in regio Rotterdam. Ons <a href="https://berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> staat klaar om je te helpen bij elke stap van je zzp-reis bij een kinderopvang. <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>Ons platform</a> biedt een breed scala aan mogelijkheden en wij zorgen ervoor dat je snel en gemakkelijk aan de slag kunt. 
    </>
  ), 

  
};

import { AuthContext } from "./@types";
import { createCtx } from "../utils";
import { useMemo } from "react";
import SocketProvider from "store/SocketConsumer";
import { useQuery } from "react-query";

import { getApplicationData } from "utils/globalAPI/application";
import { useLocale } from "locales";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const [useUserSetting, SetUserSettingProvider] = createCtx<AuthContext>();

const useUserSettingCtx = (): AuthContext => {
  const {
    data: { data: { categories, branches, skills, dropdowns } } = {
      data: { categories: [], branches: [], skills: [] },
    },
  } = useQuery("application", getApplicationData);

  const { localeLabels } = useLocale();

  const days = [
    {
      value: "Monday",
      label: {
        en: localeLabels.LABEL_MONDAY,
        du: localeLabels.LABEL_MONDAY,
      },
      _id: "Monday",
    },
    {
      value: "Tuesday",
      label: {
        en: localeLabels.LABEL_TUESDAY,
        du: localeLabels.LABEL_TUESDAY,
      },
      _id: "Tuesday",
    },
    {
      value: "Wednesday",
      label: {
        en: localeLabels.LABEL_WEDNESDAY,
        du: localeLabels.LABEL_WEDNESDAY,
      },

      _id: "Wednesday",
    },
    {
      value: "Thursday",
      label: {
        en: localeLabels.LABEL_THURSDAY,
        du: localeLabels.LABEL_THURSDAY,
      },
      _id: "Thursday",
    },
    {
      value: "Friday",
      label: {
        en: localeLabels.LABEL_FRIDAY,
        du: localeLabels.LABEL_FRIDAY,
      },
      _id: "Friday",
    },
    {
      value: "Saturday",
      label: {
        en: localeLabels.LABEL_SATURDAY,
        du: localeLabels.LABEL_SATURDAY,
      },
      _id: "Saturday",
    },
    {
      value: "Sunday",
      label: {
        en: localeLabels.LABEL_SUNDAY,
        du: localeLabels.LABEL_SUNDAY,
      },
      _id: "Sunday",
    },
  ];

  console.log("days", categories);

  return { categories, branches, skills, dropdowns, days };
};

const UserSettingProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useUserSettingCtx();

  return (
    <SetUserSettingProvider value={auth}>
      
      {children}
      {/* </SocketProvider> */}
    </SetUserSettingProvider>
  );
};

export const UserSettingsContext = () => {
  const userSetting = useUserSetting();
  return userSetting;
};

export default UserSettingProvider;

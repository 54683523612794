/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { queryClient } from "utils/QueryClient";
let isProduction = process.env.NODE_ENV === "production";
export const IsLocal = false;
// is Development
export const apiUrl = isProduction
  ? process.env.REACT_APP_API_URL
  : IsLocal
  ? // "http://192.168.0.107:3001/api"
    process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL;
axios.defaults.baseURL = apiUrl;

axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.responseType = "json";
// axios.defaults.withCredentials = true;

/**
 * Attaches URL route parameters to the given URL
 * @param {String} url Url for the API with route parameters
 * @param {Oject} params The route prametrs with their corresponding values
 */
const formatUrl = (url: string, params: { [key: string]: string }) => {
  if (!params) return url;

  // Regex to match all route parmater placeholders
  const re = new RegExp("/:(.+?)(/|$)", "g");
  // Throw Error if Insufficinet (or) excess parameters
  if ((url.match(re) || []).length !== Object.keys(params).length) {
    throw Error("Insufficinet (or) excess parameters while formating API URL");
  }

  // Replace all route parmater placeholders with their corresponding values
  return url.replace(re, (...p) => `/${params[p[1]]}${p[2]}`);
};

/**
 *
 * @param {Method} method "get" | "GET" | "delete" | "DELETE" | "head" | "HEAD" | "options" | "OPTIONS" | "post" | "POST" | "put" | "PUT" | "patch" | "PATCH" | "purge" | "PURGE" | "link" | "LINK" | "unlink" | "UNLINK"
 * @param path url after /api
 * @param data body
 * @param params query parameter
 * @returns
 */
const apiRequest = async (config: { [key: string]: any }) => {
  try {
    const { url, urlParams, headers, token } = config;

    config.url = formatUrl(url, urlParams);

    config.headers = { ...headers };

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios(config);
    debugger
    if (response?.data?.backendError) {
      throw {
        response: {
          data: response?.data?.err,
          status: response?.data?.status,
        },
      };
    }

    return Promise.resolve(response);
  } catch (err: any) {
    if (err?.response?.status === 401) {
      // Invalidate Refresh Token Query
      queryClient.invalidateQueries("refreshToken");
    }
    return Promise.reject(err);
  }
};
export const removeHeader = () => {
  axios.defaults.headers.common["Authorization"] = "";
};

export default apiRequest;
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ACCOUNT_FORMS,
  JOB_FORMS,
  REGISTRATION_FORMS,
  SETTING_FORM,
  UPDATE_PASSWORD_FORMS,
  authWithTypes,
  locale,
} from "../../../types/forms";
import { AxiosResponse } from "axios";

import apiRequest from "utils/axios";
import { GetOrganizationWorkerResponse, profileStatsTypes } from "./types";
// import { CreateOrganizationJob } from "types/schema";

//  /Update Profile

export const updateProfileAPI = async (
  data: ACCOUNT_FORMS,
  token: string
): Promise<AxiosResponse<GetOrganizationWorkerResponse, any>> => {
  console.log("updateProfileAPI", data, "token", token);

  const response = await apiRequest({
    method: "put",
    url: `/account`,
    data,
    token,
  });
  return response;
};

export const updateCertificateAPI = async (
  data: { certificate: string[] },
  token: string
): Promise<AxiosResponse<GetOrganizationWorkerResponse, any>> => {
  console.log("update Certificate", data, "token", token);

  const response = await apiRequest({
    method: "put",
    url: `/account/certificate`,
    data,
    token,
  });
  return response;
};
export const changeLocaleApi = async (
  data: { locale: "en" | "du" },
  token: string
): Promise<AxiosResponse<GetOrganizationWorkerResponse, any>> => {
  console.log("update Certificate", data, "token", token);

  const response = await apiRequest({
    method: "put",
    url: `/account/changeLanguage`,
    data,
    token,
  });
  return response;
};

//  /Update setting

export const updateSettingAPI = async (
  data: SETTING_FORM,
  token: string
): Promise<AxiosResponse<GetOrganizationWorkerResponse, any>> => {
  console.log("updateSettingAPI", data, "token", token);

  const response = await apiRequest({
    method: "put",
    url: `/account/setting`,
    data,
    token,
  });
  return response;
};

export const apiMuteNotification = async (
  data: {
    alerts: boolean;
    conversationsId: string;
  },
  token: string
): Promise<AxiosResponse<GetOrganizationWorkerResponse, any>> => {
  console.log("updateSettingAPI", data, "token", token);

  const response = await apiRequest({
    method: "put",
    url: `/account/sms/notification`,
    data,
    token,
  });
  return response;
};

//  /Delete Account

export const deleteAccountAPI = async (
  // data: SETTING_FORM,
  token: string
): Promise<AxiosResponse<GetOrganizationWorkerResponse, any>> => {
  console.log("deleteAccountAPI", "token", token);

  const response = await apiRequest({
    method: "delete",
    url: `/account`,
    token,
  });
  return response;
};
export const statsApi = async (
  period: "monthly" | "yearly",
  date: Date,
  token: string
): Promise<AxiosResponse<profileStatsTypes, any>> => {
  const response = await apiRequest({
    method: "get",
    url: `/account/profileState?date=${date}&earningPeriodType=${period}`,
    token,
  });
  return response;
};
export const ApiChangePassword = async (
  data: UPDATE_PASSWORD_FORMS,
  token: string
): Promise<AxiosResponse<profileStatsTypes, any>> => {
  const response = await apiRequest({
    method: "put",
    url: `/account/changepassword`,
    token,
    data,
  });
  return response;
};

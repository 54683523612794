export default {
  BUTTON_WORKER_DETAIL: "Details",
  BUTTON_UNBLOCK: "deblokkeren",

  BUTTON_GET_STARTED_FOR_EMPLOYEE: "Aanmelden als medewerker",
  BUTTON_GET_STARTED_FOR_FREELANCER: "Aanmelden als freelancer",
  BUTTON_GET_STARTED_FOR_ORGANIZATION: "Aanmelden als organisatie",
  BUTTON_LOGIN: "Inloggen",
  BUTTON_SIGNUP: "Aanmelden",
  BUTTON_LOGIN_WITH_GOOGLE: "Inloggen met Google",
  BUTTON_LOGIN_WITH_FACEBOOK: "Inloggen met Apple",
  BUTTON_LOGIN_WITH_APPLE: "Inloggen met Apple",
  BUTTON_CONTACT_US:"Contacteer ons",
  BUTTON_GO_BACK: "Ga terug",
  BUTTON_CONTINUE: "Doorgaan",
  BUTTON_CREATE: "Account aanmaken",
  BUTTON_UPLOADING: "Uploaden ...",
  BUTTON_CREATING: "Aanmaken ...",
  BUTTON_CHECK_EMAIL: "Check je e-mail",
  BUTTON_CLEAR_ALL: "Wis alles",
  BUTTON_SAVE: "Opslaan",

  BUTTON_EDIT_TASK: "Wijzig taak",
  BUTTON_DELETE_TASK: "Verwijder taak",

  BUTTON_SAVED: "Opgeslagen",
  BUTTON_APPLY: "Dienst accepteren",
  BUTTON_SUBMIT: "Indienen",
  BUTTON_CANCEL: "Annuleren",
  BUTTON_CANCELED: "Geannuleerd",
  BUTTON_MUTE: "Stil",
  BUTTON_REPORT: "Rapportage",
  BUTTON_RATE_LATTER: "Review",
  BUTTON_CLOSE: "Sluiten",
  BUTTON_NO_CANCEL: "Nee, annuleer",
  BUTTON_FAVOURITE_JOBS: "Favoriete diensten",
  BUTTON_RECEIVED_INVITATIONS: "Ontvangen uitnodigingen",
  BUTTON_SUBMITTED: "Ingediend",
  BUTTON_CANCELED_JOBS: "Geannuleerde diensten",
  BUTTON_COMPLETED_JOBS: "Voltooide diensten",

  BUTTON_WORK_SHIFT: "Tijd van shift",
  BUTTON_RATE_OF_JOBS: "Berekende tarief",
  BUTTON_INVOICES: "Facturen",
  BUTTON_SAVE_CHANGES: "Wijzigingen opslaan",
  BUTTON_YES_DELETE: "Ja, verwijderen",
  BUTTON_YES_COMPLETE: "Ja, voltooien",
  BUTTON_YES_CANCEL: "Ja, annuleren",
  BUTTON_YES_Pause: "Ja, pauzeren",
  BUTTON_YES_Resume: "Ja, hervatten",
  BUTTON_SHFIT_PAGE: "Shift Pagina",
  BUTTON_RATE: "Tarief",
  BUTTON_YES_HIRE: "Ja, aanmelden",
  BUTTON_YES_LOGOUT: "Ja, uitloggen",
  BUTTON_YES: "Ja",
  BUTTON_SCHEDULE: "Rooster",
  BUTTON_ADD_AVAILABILITY: "Beschikbaarheid toevoegen",

  BUTTON_ADD_NEW_LOCATION: "Nieuwe locatie toevoegen",
  BUTTON_SELECT_EXISTING_LOCATION: "Select bestaande Locatie",

  BUTTON_ADD_SCHEDULE: "Rooster toevoegen",
  BUTTON_AVAILABILITY: "Beschikbaarheid",
  BUTTON_TASKS: "Taken",
  BUTTON_FREELANCERS: "Freelancers",
  BUTTON_EMPLOYEE: "Medewerker",
  BUTTON_ONGOING_JOBS: "Doorlopende diensten",
  BUTTON_UNDER_REVIEW: "Onder beoordeling",
  BUTTON_FILLED_BY_ADMIN: "Ingevuld door beheerder",
  BUTTON_FILLED_BY_USERS: "Ingevuld door gebruikers",
  BUTTON_COMPLETED: "Voltooid",
  BUTTON_PAUSED: "Gepauzeerd",
  BUTTON_DECLINE: "Weigeren",
  BUTTON_DECLINED: "Geweigerd",
  BUTTON_COMPLETE: "Voltooien",
  BUTTON_PART_TIME: "Dag",
  BUTTON_FULL_TIME: "Nacht",
  BUTTON_POST_NEW_JOB: "Plaats een nieuwe dienst",
  BUTTON_POST_FIND_NEW_JOB: "Vind nieuwe diensten",
  BUTTON_POST_NOW: "Nu posten",
  BUTTON_EDIT_NOW: "Nu bewerken",
  BUTTON_ADD_NEW: "Nieuwe toevoegen",

  BUTTON_DAY: "Dag",
  BUTTON_WEEK: "Week",
  BUTTON_MONTH: "Maand",

  BUTTON_ACCEPT: "Accepteren",
  BUTTON_GENRATE_INVOICE: "Genereer factuur",
  BUTTON_FOR_MORE_INFO:"Meer informatie",
  BUTTON_CONFIRM: "Bevestigen",
  BUTTON_BLOCK: "Blokkeer",
  BUTTON_REJECT: "Afwijzen",
    
  BUTTON_REJECTED: "Afgewezen",
  BUTTON_ACCEPTED: "Geaccepteerd",
  BUTTON_JOB_DETAILS: "Dienst details",

  BUTTON_SHOW_ALL: "Toon alles",
  BUTTON_DELETE: "Verwijderen",
  BUTTONS_JOB_FULLFILLED_BY_USERS: "Dienst vervuld door gebruikers",
  BUTTONS_JOB_IN_REVIEW: "Onder beoordeling",
  BUTTONS_JOB_FULLFILLED_BY_ADMIN: "Dienst vervuld door beheerder",
  BUTTON_EDIT_DETAILS: "Details bewerken",
  BUTTON_INVITE_WORKER: "Nodig medewerkers uit",
  BUTTON_HIRE_DIRECTLY: "Huur per direct een medewerker",
  BUTTON_ADD_JOB: "Maak een dienst aan",
  BUTTON_ADD_EDUCATION: "Maak een opleiding aan",
  BUTTON_INVITE: "Nodig uit",
  BUTTON_INVITED: "Uitgenodigd",
  BUTTON_HIRED: "Ingehuurd",
  BUTTON_CHANGE_RATE: "Wijzig tarief",
  BUTTON_REPEAT_CONTRACT: "Herhaal dienst",
  BUTTON_FAVOURITE: "Favorieten",
  BUTTON_APPLIED: "Inhuren",
  BUTTON_ALREADY_APPLIED: "Al ingehuurd",
  BUTTON_DAY_SHIFT: "Dag",
  BUTTON_NIGHT_SHIFT: "Nacht",
  BUTTON_NO: "Nee",
  BUTTON_DELETE_SHIFT: "Verwijder dienst",
  BUTTON_ADD_MORE_SHIFT: "Meer diensten toevoegen",
  BUTTON_ADD_MORE_TASK: "Meer taken toevoegen",

  BUTTONS_BY_ADMIN: "Door beheerder",
  BUTTONS_BY_USERS: "Door gebruikers",

  BUTTON_LOGOUT: "Uitloggen",
  BUTTON_SEND_EMAIL: "E-mail verzenden",
  BUTTON_TO_LOGIN: "Terug naar inlogpagina",
  BUTTON_CHANGE_PW: "Wachtwoord wijzigen",
  BUTTON_PREV_MONTH: "Vorige maand",
  BUTTON_NEXT_MONTH: "Volgende maand",
  BUTTON_SHIPPED: "Verzonden",
  BUTTON_DELIVERED: "Bezorgd",

  BUTTON_ORDER_ISSUED: "Probleem opgetreden",
  BUTTON_DETAIL: "Details",
  BUTTON_ORDER: "Maak een bestelling",
  BUTTON_NEXT: "Volgende",
  BUTTON_CONFIRM_ORDER: "Bevestigen",
  BUTTON_CONFIRM_DEACTIVATE: "Deactiveren",
  BUTTON_CONFIRM_ACTIVATE: "Activeren",
  BUTTON_APPLY_FILTER: "Filter toepassen",
  BUTTON_CLEAR_FILTER: "Filter wissen",

  BUTTON_IN_TRANSIT: "Onderweg",
  BUTTON_DELIVERY_COMPLETE: "Levering voltooid",
  BUTTON_PREVIOUS: "Vorige",
  BUTTON_REGISTER: "Registreren",
  BUTTON_LOADING: "Laden",

  BUTTON_SEARCH_ZIPCODE: "Zoek adres",
  BUTTON_BACK: "Terug",
  BUTTON_ORDER_CANCEL: "Annuleren",
  BUTTON_ACTIVATE_ACCOUNT: "Activeer dit account",
  BUTTON_ALLOWED_BRANCHES: "Toegestane branches",
  BUTTON_POST_JOB: "Dienst plaatsen",

   BUTTON_DEACTIVATE_ACCOUNT: "Deactiveer dit account",
   BUTTON_SEE_DETAILS: "Zie details",
   BUTTON_SEE_ALL_USER: "Bekijk alle gebruikers >> ",
   BUTTON_SEE_ALL_AVAILABILITY: "Bekijk alle beschikbaarheid",
   BUTTON_ADD_BRANCHES: "Branches toevoegen",
   BUTTON_ADD_BRANCH: "Branch toevoegen",
   BUTTON_ADD_CATEGORIES: "Categorieën toevoegen",
   BUTTON_ADD_SKILL_NAME: "Vaardigheid toevoegen",
   BUTTON_GENRATE_REPORT: "Rapport genereren",
   BUTTON_EDIT: "Bewerken",
   BUTTON_DOWNLOAD: "Downloaden",

   BUTTON_ALLOW_EMPLOYEE_TO_WORK: "Accepteren op basis van VOG",
   BUTTON_DECLINE_EMPLOYEE_CERTIFICATE: "Weigeren op basis van VOG",

   BUTTON_UNMUTE: "Dempen opheffen",

   BUTTON_DELETED_JOBS: "Verwijderd",

   BUTTON_FULL_SCREEN: "Volledig scherm",

  //  new one
  BUTTON_CHANGE_PASSWORD:"Verander wachtwoord",
  BUTTON_UPDATE_USER:"Gebruiker bijwerken",
  BUTTON_ORGANIZATION_SCHEDULE:"Organisatieschema",
  
};
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "./styles/color.css";
import "./styles/font.css";
import "./styles/tailwind.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import 'react-datetime-picker/dist/DateTimePicker.css'
// import 'react-calendar/dist/Calendar.css'
// import 'react-clock/dist/Clock.css'

// if app is in production mode then make the console log empty
// if (process.env.NODE_ENV === "production") {
//   console.log = () => {};
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/serviceWorker.js")
//       .then((reg) => {
//         console.log("Success: ", reg.scope);
//       })
//       .catch((err) => {
//         console.log("Failure: ", err);
//       });
//   });
// }














